


// // import React from "react";
// // import {
// //     Box,
// //     Card,
// //     CardMedia,
// //     CardContent,
// //     Typography,
// //     Button,
// //     Grid,
// //     Avatar,
// //     IconButton,
// // } from "@mui/material";
// // import { AccessTime, DoNotTouchRounded, Person } from "@mui/icons-material";
// // import LatestNewsImage from "../../Assets/latestNewsImage.jpg"
// // import { FaRegUser } from "react-icons/fa";
// // import { GoDotFill } from "react-icons/go";




// // const NextStepForwardGold = () => {
// //     return (
// //         <Box sx={{ p: 3 }}>
// //             <Box sx={{ textAlign: "center", mb: 4, display: "flex", justifyContent: "center" }}>
// //                 {/* Header Section */}
// //                 <Box>
// //                     <Typography
// //                         variant="h4"
// //                         gutterBottom
// //                         sx={{ paddingBottom: "5px", fontSize: "30px", width: "600px" }}
// //                     >
// //                         Your Next Step Towards Gold Purchase
// //                         <span style={{ color: "black", fontWeight: "800" }}> Success Starts Here!</span>
// //                     </Typography>
// //                 </Box>
// //             </Box>
// //             {/* Main Content */}
// //             <Grid container spacing={3}>
// //                 {/* Left Highlighted Card */}
// //                 <Grid item xs={12} md={6}>
// //                     <Card sx={{ borderRadius: 2, overflow: "hidden" }}>
// //                         <CardMedia
// //                             component="img"
// //                             image={LatestNewsImage} // Replace with actual image
// //                             alt="Main Image"
// //                         />
// //                         <CardContent>
// //                             <Box display="flex" alignItems="center" gap={2} mb={1}>



// //                                 <Typography variant="body2" sx={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#C38F51" }}> <FaRegUser style={{ marginRight: "5px" }} />Jenny Watson</Typography>
// //                                 <Typography variant="body2" color="text.secondary" sx={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#C38F51" }}>
// //                                     <GoDotFill fontSize="small" style={{ marginRight: "5px" }} /> 24 Jan 2024
// //                                 </Typography>
// //                             </Box>
// //                             <Typography variant="h6" fontWeight="bold">
// //                                 Expert Insights at Your Fingertips
// //                             </Typography>
// //                             <Typography
// //                                 variant="body2"
// //                                 color="text.secondary"
// //                                 paragraph
// //                                 sx={{
// //                                     display: 'block',
// //                                     overflow: 'hidden',
// //                                     // whiteSpace: 'nowrap',
// //                                     // textOverflow: 'ellipsis',
// //                                     height: '100px',
// //                                     lineHeight: '1.5',  // Adjust this based on the font size
// //                                     // maxWidth: '50%',  // Ensure it doesn't overflow horizontally
// //                                 }}
// //                             >
// //                                 Lorem Ipsum is simply dummy text of the printing and typesetting
// //                                 industry. Lorem Ipsum has been the industry's standard dummy text
// //                                 ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting
// //                                 industry. Lorem Ipsum has been the industry's standard dummy text
// //                                 ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting
// //                                 industry. Lorem Ipsum has been the industry's standard dummy text
// //                                 ever since the 1500s
// //                             </Typography>
// //                             <Box sx={{ display: "flex", justifyContent: "end" }}>


// //                                 <Button variant="contained" sx={{ background: "#C38F51", color: "white", borderRadius: '23px', textTransform: "none", p: "5px 20px" }} >
// //                                     Let's Explore...
// //                                 </Button>
// //                             </Box>
// //                         </CardContent>
// //                     </Card>
// //                 </Grid>

// //                 {/* Right Smaller Cards */}
// //                 <Grid item xs={12} md={6}>
// //                     <Grid container spacing={2}>
// //                         {[1, 2, 3, 4].map((_, index) => (
// //                             <Grid item xs={12} key={index}>
// //                                 <Card sx={{ display: "flex", p: 0 ,height:"170px"}}>
// //                                     <CardMedia
// //                                         component="img"
// //                                         sx={{ width: "170px", height: "170px" }}
// //                                         image="https://via.placeholder.com/120x100" // Replace with actual image
// //                                         alt="Small Card Image"
// //                                     />
// //                                     <CardContent sx={{ display: "flex", justifyContent: "center", height: "170px", alignItems: "center", px: 2 }} style={{ paddingBottom: "0" }}>
// //                                         <Box pb={0}>
// //                                             <Box display="flex" alignItems="center" gap={2} mb={1}>



// //                                                 <Typography variant="body2" sx={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#C38F51" }}> <FaRegUser style={{ marginRight: "5px" }} />Jenny Watson</Typography>
// //                                                 <Typography variant="body2" color="text.secondary" sx={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#C38F51" }}>
// //                                                     <GoDotFill fontSize="small" style={{ marginRight: "5px" }} /> 24 Jan 2024
// //                                                 </Typography>
// //                                             </Box>
// //                                             <Typography variant="subtitle1" fontWeight="bold">
// //                                                 Discover the Golden Opportunity
// //                                             </Typography>
// //                                             <Typography variant="body2" color="text.secondary" sx={{fontSize:"12px",height:"50px"}}>
// //                                                 Lorem Ipsum is simply dummy text of the printing and typesetting
// //                                                 industry Lorem Ipsum is simply dummy text of the printing and typesetting
// //                                                 industry...
// //                                                 <Button variant="body2" sx={{fontSize:"12px",color:'#C38F51'}}>
// //                                                     Read more
// //                                                 </Button>
// //                                             </Typography>
// //                                         </Box>
// //                                     </CardContent>
// //                                 </Card>
// //                             </Grid>
// //                         ))}
// //                     </Grid>
// //                 </Grid>
// //             </Grid>
// //         </Box>
// //     );
// // };

// // export default NextStepForwardGold;







import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Button,
    Grid,
    Avatar,
    IconButton,
} from "@mui/material";
import { AccessTime, DoNotTouchRounded, Person } from "@mui/icons-material";
import LatestNewsImage from "../../Assets/latestNewsImage.jpg"
import { FaRegUser } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { date } from "yup";
import { adminUrl } from "../../constants";





const NextStepForwardGold = ({ nextStepDates, handleReadMoreOpenModal }) => {
    const data = nextStepDates?.[0]?.additional_data?.card_design || [
        {
            title: "Jenny Watson",
            date: "24 Jan 2024",
            secondary_title: "Expert Insights at Your Fingertips",
            content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500... Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500...Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500... Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500..."
        },
        {
            title: "Jenny Watson",
            date: "25 Jan 2024",
            secondary_title: "Discover the Golden Opportunity",
            content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500...",
        },
        {
            title: "Jenny Watson",
            date: "26 Jan 2024",
            secondary_title: "Your Gateway to Success",
            content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500...",
        },
        {
            title: "Jenny Watson",
            date: "27 Jan 2024",
            secondary_title: "Take the Next Step",
            content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500...",
        },
        {
            title: "Jenny Watson",
            date: "28 Jan 2024",
            secondary_title: "Embrace the Future of Gold",
            content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500...",
        },
    ];

    const [modalData, setModalData] = useState()
    useEffect(() => {
        setModalData(
            {
                date: nextStepDates?.[0]?.additional_data?.card_design[0].card_date,
                title: nextStepDates?.[0]?.additional_data?.card_design[0].cart_title_secondtext,
                secondary_title: nextStepDates?.[0]?.additional_data?.card_design[0].title,
                image: nextStepDates?.[0]?.additional_data?.card_design[0].logo,
                textContent: nextStepDates?.[0]?.additional_data?.card_design[0].content


            }
        )

    }, [])
    return (
        <Box sx={{ p: 4, mt: 4, background: "#F7F7F7" }}>
            <Box sx={{ textAlign: "center", mb: 4, display: "flex", justifyContent: "center" }}>
                {/* Header Section */}
                <Box>
                    <Typography
                        variant="h3"
                        gutterBottom
                        sx={{
                            paddingBottom: "5px",
                            //  fontSize: "30px",
                            // fontSize: { xs: 24, md: 32 },

                            maxWidth: "600px"
                        }}
                    >
                        {nextStepDates?.[0]?.heading || "Default Heading"}
                        <span style={{ color: "black", fontWeight: "800" }}> {nextStepDates?.[0]?.sub_heading || "Default Sub-heading"}</span>
                    </Typography>
                </Box>
            </Box>
            {/* Main Content */}
            <Grid container spacing={3}>
                {/* Left Highlighted Card */}
                <Grid item xs={12} md={5} sx={{ maxHeight: "700px" }}>
                    <Card sx={{ borderRadius: 2, overflow: "hidden", background: "none", boxShadow: "none" }}>
                        <CardMedia
                            component="img"
                            image={`${adminUrl}${nextStepDates?.[0]?.additional_data?.card_design[0].logo}`} // Replace with actual image
                            alt="Main Image"
                        />
                        <CardContent sx={{ p: "16px 0px" }}>
                            <Box display="flex" alignItems="center" gap={2} mb={1}>



                                <Typography variant="body1" sx={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#C38F51" }}>
                                    <FaRegUser style={{ marginRight: "5px" }} /> {nextStepDates?.[0]?.additional_data?.card_design[0].cart_title_secondtext || "Default title"}</Typography>
                                <Typography variant="body1" color="text.secondary" sx={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#C38F51" }}>
                                    <GoDotFill fontSize="small" style={{ marginRight: "5px" }} />  {nextStepDates?.[0]?.additional_data?.card_design[0].card_date || "Default date"}
                                </Typography>
                            </Box>
                            <Typography variant="h6">
                                {nextStepDates?.[0]?.additional_data?.card_design[0].title || "Default secondary_title"}
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ textAlign: "justify" }}>
                                <Box
                                    sx={{
                                        // height: '100px',
                                        lineHeight: '1.5',  // Adjust this based on the font size
                                        // //                                     // maxWidth: '50%',
                                        overflow: "hidden",
                                        maxHeight: { xs: "150px", sm: "100px", md: "290px" },
                                    }}
                                >
                                    {(nextStepDates?.[0]?.additional_data?.card_design[0].content || "").split(" ").slice(0, 150).join(" ")}
                                    {(nextStepDates?.[0]?.additional_data?.card_design[0].content || "").split(" ").length > 150 && (
                                        <>
                                            <span>...</span>
                                            <span onClick={() => { handleReadMoreOpenModal(modalData) }} variant="text" style={{ color: "#C38F51", cursor: "pointer" }}>
                                                Read more
                                            </span>
                                        </>)}
                                    {/* {nextStepDates?.[0]?.additional_data?.card_design[0].content || "Default content"} */}


                                </Box>
                                {/* <span onClick={() => { handleReadMoreOpenModal(modalData) }} variant="text" style={{ color: "#C38F51", cursor: "pointer" }}>
                                    Read more
                                </span> */}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Right Smaller Cards */}
                <Grid item xs={12} md={7}>
                    <Grid container spacing={2}>
                        {data?.slice(1).map((item, index) => (

                            <Grid item xs={12} key={index}>
                                <Card sx={{
                                    display: "flex", p: 0,
                                    // height: "170px",
                                    boxShadow: "none", background: "none"
                                }}>
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            width: "150px", height: "150px",
                                            borderRadius: "10px"

                                        }}
                                        image={`${adminUrl}${item.logo}`} // Replace with actual image
                                        alt="Small Card Image"
                                    />
                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between", // Ensures the content is spaced evenly
                                            alignItems: "flex-start", // Align content to the left
                                            // height: "170px",
                                            p: "0px 0px 0 8px",
                                            paddingBottom: 0, // Remove unnecessary padding
                                        }}
                                    >
                                        <Box sx={{
                                            width: "100%",
                                            // height: "170px",

                                        }}>
                                            <Box
                                                sx={{
                                                    gap: { xs: 0, md: 2 },
                                                    display: "flex",
                                                    justifyContent: { xs: "start", md: "space-between" },
                                                    flexDirection: { xs: "column", md: "row" }, // Flex direction: column for mobile, row for desktop
                                                    alignItems: { xs: "start", md: "center" },   // Align items: end for mobile, center for desktop
                                                }}
                                                mb={1}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        color: "#C38F51",
                                                        // fontSize: { xs: "10px", md: "14px" },
                                                    }}
                                                >
                                                    <FaRegUser style={{ marginRight: "5px" }} />
                                                    {item.cart_title_secondtext}
                                                </Typography>
                                                <Typography variant="body1" color="text.secondary" sx={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#C38F51" }}>

                                                    <GoDotFill style={{ marginRight: "5px" }} />
                                                    {item.card_date}

                                                </Typography>
                                            </Box>

                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    // fontSize: { xs: "12px", md: "16px" },
                                                    // fontWeight: { xs: "400", md: "700" },
                                                    mb: 1


                                                }}>
                                                {item.title}

                                            </Typography>
                                            <Typography variant="body1" color="text.secondary" >
                                                <Box
                                                    sx={{
                                                        textAlign: "justify",

                                                        overflow: "hidden",
                                                        // maxHeight: { xs: "70px", md: "65px" },
                                                    }}
                                                >
                                                    {(item?.content || "").split(" ").slice(0, 35).join(" ")}
                                                    {(item?.content || "").split(" ").length > 35 && (
                                                        <>
                                                            <span>...</span>
                                                            <span onClick={() => {
                                                                handleReadMoreOpenModal({
                                                                    date: item.card_date,
                                                                    title: item.cart_title_secondtext,
                                                                    secondary_title: item.title,
                                                                    image: item.logo,
                                                                    textContent: item.content,
                                                                })
                                                            }} variant="text" style={{ color: "#C38F51", cursor: "pointer" }}>
                                                                Read more
                                                            </span>
                                                        </>
                                                    )}
                                                </Box>
                                                {/* {(item?.content || "").split(" ").length > 35 && (
                                                    <span onClick={() => {
                                                        handleReadMoreOpenModal({
                                                            date: item.card_date,
                                                            title: item.cart_title_secondtext,
                                                            secondary_title: item.title,
                                                            image: item.logo,
                                                            textContent: item.content,
                                                        })
                                                    }} variant="text" style={{ color: "#C38F51", cursor: "pointer" }}>
                                                        Read more
                                                    </span>
                                                )} */}
                                            </Typography>


                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    );
};

export default NextStepForwardGold;

