


// import React from "react";
// import { Box, Typography, Grid } from "@mui/material";
// import StorefrontIcon from "@mui/icons-material/Storefront";
// import styled from "@emotion/styled";
// import ProsperityCard from "../../../Components/ProsperityCard/ProsperityCard";
// import Pusrchase_GoldStakeCard from "../../../Components/Pusrchase_GoldStakeCard/Pusrchase_GoldStakeCard";
// import SchemeCard from "../../../Components/SchemeCard/SchemeCard";
// import cardImage from "../../../Assets/home_page_Image.png"



// const PurchaseSchemes = ({targetRef}) => {
//     const schemes = [
//         {
//             id: 1,
//             name: "Scheme Name 11111111",
//             valueOfPurchase: "$1000",
//             purchase: "13.8 Grm",
//             maturity: "24 Month's",
//             lockIn: "24 Month's",
//             benefit: "6%*",
//             closingDate: "15/Mar/2025",
//             backgroundImage: cardImage, // replace with the background image URL
//             price: 100,
//         },
//         {
//             id: 2,
//             name: "Scheme Name 2",
//             valueOfPurchase: "$10000",
//             purchase: "138 Grm",
//             maturity: "24 Month's",
//             lockIn: "24 Month's",
//             benefit: "6%*",
//             closingDate: "15/Mar/2025",
//             backgroundImage: cardImage, // replace with the background image URL
//             price: 500,
//         },
//     ];

//         // const dispatch = useDispatch();

//         // const handleAddToCart = (product) => {
//         //     dispatch(addToCart(product));
//         //     console.log(product,"product");

//         // };

//         // const product = {
//         //     id: 1,
//         //     name: "Product Name",
//         //     price: 100,
//         // };


//         // onClick={() => handleAddToCart(product)}
//     return (
//         <>
//             <Box sx={{ p: 4 }} id="name" ref={targetRef}>
//                 <Box sx={{ textAlign: "center", mb: 4 }}>
//                     <Typography
//                         variant="h3"
//                         gutterBottom
//                         sx={{ paddingBottom: "5px"}}
//                     >
//                         Purchase &
//                         <span style={{ color: "black", fontWeight: "800" }}>Schemes</span>
//                     </Typography>
//                     <Typography
//                      variant="body1"
//                         sx={{
//                             paddingBottom: "5px",
//                             maxWidth: "900px",
//                             margin: "0 auto",
//                             textAlign: "center",
//                             color: "#b0aaaa",

//                         }}
//                     >
//                         Lorem Ipsum is simply dummy text of the printing and typesetting industry.
//                         Lorem Ipsum has been the industry’s standard dummy text ever since the
//                         1500s, when an unknown printer took a galley of type and scrambled it to
//                         make a type specimen book.
//                     </Typography>
//                 </Box>


//                 <Box 
//                 // sx={{
//                 //       position: "relative",
//                 //       display: "flex", justifyContent: "space-between", alignItems: "center" }}
//                       >
//                     {/* <Grid
//                       container justifyContent="center" spacing={6} alignItems="center"
//                      > */}
//                         {/* {schemes.map((schemes, index) => ( */}
//                             <SchemeCard schemes={schemes} />
//                         {/* ))} */}
//                     {/* </Grid> */}
//                 </Box>



//             </Box>
//         </>
//     );
// };

// export default PurchaseSchemes;





import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import styled from "@emotion/styled";
import ProsperityCard from "../../../Components/ProsperityCard/ProsperityCard";
import Pusrchase_GoldStakeCard from "../../../Components/Pusrchase_GoldStakeCard/Pusrchase_GoldStakeCard";
import SchemeCard from "../../../Components/SchemeCard/SchemeCard";
import cardImage from "../../../Assets/home_page_Image.png"
import actions from "../../../ReduxStore/actions/index";
import { useDispatch, useSelector } from "react-redux";



const PurchaseSchemes = ({ targetRef, ShemesDatas }) => {

    const dispatch = useDispatch();

    const { PurchaseSchemes } = useSelector((state) => state?.PurchaseSchemes);


    useEffect(() => {
        const fetchData = async () => {
            const data1 = { data: {}, method: "get", apiName: "getAllActivePlansApproved" };
            await dispatch(actions.PURCHASESCHEMES(data1));
        };
        fetchData();
    }, [dispatch]);


    console.log(PurchaseSchemes?.data, "PurchaseSchemesPurchaseSchemes");


    const schemes = [
        {
            plan_id: 1,
            title: "Scheme Name 11111111",
            value_of_purchase: "1000",
            purchase_stake: "13.8 Grm",
            maturity_in_months: "24 Month's",
            lock_in_months: "24 Month's",
            reap_percentage: "6%*",
            closure_date: "15/Mar/2025",
            image_url: cardImage, // replace with the background image URL
            reap_months: "43",
            "primary_button_text": "Invest Now",
            "secondary_button_text": "Know More",
            // price: 100,
        },
        {
            id: 2,
            name: "Scheme Name 2",
            valueOfPurchase: "$10000",
            purchase: "138 Grm",
            maturity: "24 Month's",
            lockIn: "24 Month's",
            benefit: "6%*",
            closingDate: "15/Mar/2025",
            backgroundImage: cardImage, // replace with the background image URL
            // price: 500,
        },

    ];

    // const dispatch = useDispatch();
    // const handleAddToCart = (product) => {
    //     dispatch(addToCart(product));
    //     console.log(product,"product");

    // };  
    // const product = {
    //     id: 1,
    //     name: "Product Name",
    //     price: 100,
    // };
    // onClick={() => handleAddToCart(product)}
    return (
        <>
            <Box sx={{ p: 4 }} id="name" ref={targetRef}>
                <Box sx={{ textAlign: "center", mb: 4 }}>
                    <Typography
                        variant="h3"
                        gutterBottom
                        sx={{ paddingBottom: "5px" }}
                    >
                        {ShemesDatas[0]?.heading || "Default-Heading"} &
                        <span style={{ color: "black", fontWeight: "800" }}> {ShemesDatas[0]?.sub_heading || "Default-Sub Heading"}</span>
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            paddingBottom: "5px",
                            maxWidth: "900px",
                            margin: "0 auto",
                            textAlign: "center",
                            color: "#b0aaaa",

                        }}
                    >
                        {ShemesDatas[0]?.content || "Default-content"}
                    </Typography>
                </Box>

                {PurchaseSchemes?.data?.length > 0 ? (
                    <Box>
                        {/* {PurchaseSchemes.data.map((scheme, index) => ( */}
                            <SchemeCard schemes={schemes} />
                        {/* ))} */}
                    </Box>
                ) : (
                    <Typography variant="h6" color="gray">
                        No active Plans
                    </Typography>
                )}
{/*  */}

            </Box>
        </>
    );
};

export default PurchaseSchemes;
