






import React, { useEffect } from 'react';
import AddtoCartMainPage from './AddtoCartMainPage';
import AddToCartSchemesById from './AddToCartSchemesById';
import GoldInvestment from './GoldInvestment';
import GoldInfoAccordion from './GoldInfoAccordion';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../ReduxStore/actions';


const AddToCartHome = () => {
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const { id } = useParams();
    console.log(id, "idddddddddddd")
    const { getPlanById } = useSelector((state) => state?.getPlanById);
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchData = async () => {
            const requestData = {
                data: {

                },
                method: "get",
                apiName: `getPlanById/${id}`,
            };

            const responseData = await dispatch(actions.GETPLANBYID(requestData));

        }
        fetchData()


    }, [])
    return (

        <div style={{ position: "relative" }}>
            {/* <MyAppBar /> */}

            {/* <AddtoCartMainPage /> */}
            <AddToCartSchemesById/>
            <GoldInvestment />
            <GoldInfoAccordion />

            {/* <Footer /> */}


        </div>
    )
}


export default AddToCartHome;



