
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Grid,
  Box,
  InputBase,
  Menu,
  MenuItem,
  Button,
  CardMedia,
  Avatar,
  Stack,
  Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./CustomAppBar.css";
import call_image_white from "../../Assets/call_white_color.svg"
import logo_img from "../../Assets/new_logo.png";
import search_icom from "../../Assets/search_icom.svg";

import profile_icon from "../../Assets/profile_icon.svg";

import cart_icon from "../../Assets/cart_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../ReduxStore/actions";
import { use } from "react";
import { MdNotificationsNone } from "react-icons/md";
import Notification from "../Notification/Notification";





const MyAppBar = () => {

  const sessioncustomer_id = sessionStorage.getItem("sessioncustomer_id");
  const sessionFirstName = sessionStorage.getItem("sessionFirstName");
  const location = useLocation();
  const dispatch = useDispatch()
  const [drawerOpen, setDrawerOpen] = useState(false);
  const cartItemsLocal = useSelector((state) => state.cart.cart);
  console.log(cartItemsLocal, "cartItemsLocal");

  const { getCartItemCount } = useSelector((state) => state?.getCartItemCount);
  const { addMultiPurchase } = useSelector((state) => state?.addMultiPurchase);

  const { incrementQuantity } = useSelector((state) => state?.incrementQuantity);
  const { decrementQuantity } = useSelector((state) => state?.decrementQuantity);
  const { clearCart } = useSelector((state) => state?.clearCart);
  console.log(getCartItemCount, "getCartItemCountgetCartItemCount")
  useEffect(() => {
    const fetchData = async () => {
      const data1 = { data: {}, method: "get", apiName: `getCartItemCount/${sessioncustomer_id}` };
      await dispatch(actions.GETCARTITEMCOUNT(data1));
    };
    fetchData();
  }, [dispatch, incrementQuantity, decrementQuantity, clearCart]);

  const [notificationData, setNotificationData] = useState([])
  console.log(notificationData, "notificationDatanotificationData")
  const { getCustomerNotifications } = useSelector((state) => state?.getCustomerNotifications);
  const { markNotificationAsReadForCustomer } = useSelector((state) => state?.markNotificationAsReadForCustomer);

  useEffect(() => {
    if (sessioncustomer_id !== null && sessioncustomer_id !== "null") {

    const fetchData = async () => {
      const data1 = { data: {}, method: "get", apiName: `getCustomerUnreadNotifications/${sessioncustomer_id}` };
      const responseData = await dispatch(actions.GETCUSTOMERNOTIFICATIONS(data1));
      if (responseData?.payload?.message === "Customer notifications fetched successfully.") {
        setNotificationData(responseData?.payload?.data?.map((data) => ({
          id: data?.notification_id,
          title: data?.title,
          content: data?.message,
          type: data?.type,
          status: data?.status
        })));

      }
    };
    fetchData();
  }
  }, [dispatch,markNotificationAsReadForCustomer]);

  const navItems = [
    { label: "Dashboard", path: "/customer-dashboard", requiresSession: true },


    { label: "Home", path: "/" },
    { label: "Stake Now", path: "/stake-now" },
    { label: "Referrals", path: "/referrals" },

    { label: "About Us", path: "/about-us" },
    { label: "Blog", path: "/blogs" },


    { label: "Contact Us", path: "/contactus" },

  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const [isNotificationShow, setNotificationShow] = useState(false);


  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [profileImageIcon, setProfileImageIcon] = useState(null)
  console.log(profileImageIcon, "skdjskdjskdksdjks")


  const handleNavigationLogout = (path) => {
    sessionStorage.setItem("sessioncustomer_id", null);

    sessionStorage.setItem("sessionFirstName", "");
    setProfileImageIcon(null)
    navigate(path);
    handleMenuClose();
    setNotificationShow(false)
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleMenuClose();
  };




  const [isCurrencyMenuOpen, setCurrencyMenuOpen] = useState(false);
  const [currencyAnchorEl, setCurrencyAnchorEl] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('$ US Dollar');


  // Open menu
  const handleCurrencyMenuOpen = (event) => {
    setCurrencyAnchorEl(event.currentTarget);
    setCurrencyMenuOpen(true);
  };

  // Close menu
  const handleCurrencyMenuClose = () => {
    setCurrencyMenuOpen(false);
  };

  // Handle currency selection
  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
    console.log(`Selected Currency: ${currency}`);
    handleCurrencyMenuClose();  // Close the menu after selection
  };

  const filteredNavItems = navItems.filter((item) => {
    // Check if `requiresSession` is true and `sessioncustomer_id` is valid
    if (item.requiresSession) {
      return sessioncustomer_id && sessioncustomer_id !== "null";
    }
    return true;
  });



  const { getCustomerById } = useSelector((state) => state?.getCustomerById);

  console.log(getCustomerById, "getCustomerById")


  useEffect(() => {

    if (sessioncustomer_id !== null && sessioncustomer_id !== "null") {
      console.log("itsWorllllllll")
      const fetchData = async () => {
        const data1 = {
          data: {},
          method: "get",
          apiName: `getCustomerById/${sessioncustomer_id}`,
        };
        await dispatch(actions.GETCUSTOMERBYID(data1));
        setNotificationShow(true)
      };
      fetchData();
    }
  }, [sessioncustomer_id, dispatch]);



  useEffect(() => {
    if (getCustomerById?.data?.profileImage) {
      console.log(getCustomerById?.data?.profileImage, "Profile Image Updated");
      setProfileImageIcon(getCustomerById?.data?.profileImage);
    }
  }, [getCustomerById]);



  const [anchorElNotification, setAnchorElNotification] = useState(false);
  const [notifications] = useState([
    { id: 1, message: "New message received" },
    { id: 2, message: "Your report is ready" },
    { id: 3, message: "Server maintenance scheduled" },
  ]);

  const handleClickNotification = (event) => {
    setAnchorElNotification(true);
  };

  const handleCloseNotification = () => {
    setAnchorElNotification(false);
  };

  return (
    <>
      <Box
        sx={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "5px 20px",
          p: { xs: "0px 20px", md: "0px 20px" },

          background: "#C38F51",
          color: "white",
        }}
      >

        <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
          <img src={call_image_white} style={{ marginRight: "5px", height: "15px", width: "15px" }}></img> +1 (123) 1234567
        </Typography>

        <Button
          endIcon={<ExpandMoreIcon />}
          onClick={handleCurrencyMenuOpen}
          sx={{
            color: 'white',
            textTransform: 'none',
            fontSize: '14px',
          }}
        >
          {selectedCurrency}
        </Button>
        <Menu
          anchorEl={currencyAnchorEl}
          open={isCurrencyMenuOpen}
          onClose={handleCurrencyMenuClose}
        >
          <MenuItem onClick={() => handleCurrencySelect('$ US Dollar')}
            sx={{
              '&:hover': {
                backgroundColor: 'white',
                color: '#C38F51',
              },
            }}
            fontSize="14px"><span style={{ fontWeight: "800", marginRight: "5px" }}>$ </span>US Dollar</MenuItem>
          <MenuItem onClick={() => handleCurrencySelect('€ Euro')}
            sx={{
              '&:hover': {
                backgroundColor: 'white',
                color: '#C38F51',
              },
            }}><span style={{ fontWeight: "800", marginRight: "5px" }}>€</span>Euro</MenuItem>
          <MenuItem onClick={() => handleCurrencySelect('₹ Rupee')}
            sx={{
              '&:hover': {
                backgroundColor: 'white',
                color: '#C38F51',
              },
            }}><span style={{ fontWeight: "800", marginRight: "5px" }}>₹ </span>Rupee</MenuItem>
        </Menu>

      </Box>
      <AppBar position="sticky" sx={{ boxShadow: "none" }}>


        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",

            // width: "100%",
            // padding: "10px 50px",
            p: { xs: "10px 10px", md: "20px 30px" },

            background: "white", borderBottom: "1px solid #C38F51"
          }}
        >

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              // background: "#f0f0f0",
              borderRadius: "4px",
              padding: "2px 10px",
              marginRight: "20px",
            }}
          >

            <Box sx={{
              border: "1px solid black",
              borderRadius: "50%", background: "black",
              // padding:"10px",
              display: "flex",
              justifyContent: "center", alignItems: "center",
              height: "35px",
              width: "35px"
            }}>
              <img src={search_icom} style={{ height: "16px", width: "16px" }}></img>

            </Box>
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              sx={{ marginLeft: "10px", fontSize: "14px", borderBottom: "1.5px solid #C38F51" }}
            />
          </Box>
          {/* Logo */}
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CardMedia
              component="img"
              src={logo_img}
              alt="Logo"
              // height="50"
              sx={{ height: { xs: "30px", md: "50px" }, width: { xs: "95px", md: "180px" } }}

            // sx={{ verticalAlign: "middle" }}
            />


          </Box>

          {/* Search Bar */}


          {/* Icons */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",

              gap: "15px",
              color: "black",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {/* <AccountCircleIcon /> */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleMenuOpen}
              >
                {profileImageIcon ? (
                  <Avatar
                    src={`${profileImageIcon}`}
                    alt="John Doe"
                    sx={{
                      width: 40,
                      height: 40,
                      border: "4px solid white",
                      // marginTop: "-80px",
                      // marginLeft: "50px"
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      border: '1px solid black',
                      borderRadius: '50%',
                      background: 'black',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // height: { xs: "20px", md: '35px' },
                      // width: { xs: "20px", md: '35px' },

                      p: { xs: "5px", md: '10px' }

                    }}
                  >


                    <Box
                      component="img"
                      src={profile_icon}
                      alt="profile-icon"
                      sx={{
                        height: { xs: "14px", md: '16px' },
                        width: { xs: "14px", md: '16px' },
                      }}
                    />
                  </Box>
                )}
                <Box sx={{ paddingLeft: { xs: '5px', md: "10px" } }}>
                  <Typography variant="body1" sx={{ fontSize: { xs: '12px', md: "14px" } }}>
                    My Account
                  </Typography>

                  <Typography variant="body1" sx={{ fontSize: { xs: '10px', md: "12px" }, color: "#AAAAAA" }}>
                    {sessionFirstName ? sessionFirstName : "Register"}
                  </Typography>
                </Box>
              </Box>


              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ mt: 1 }}
              >
                {sessioncustomer_id === "null" || sessioncustomer_id === null ? (
                  // If sessionCustomerId is null or "null", show login and register options
                  <>
                    <MenuItem onClick={() => handleNavigation('/login')}>Login</MenuItem>
                    <MenuItem onClick={() => handleNavigation('/register')}>Register</MenuItem>
                  </>
                ) : (
                  // If sessionCustomerId is not null, show order history, profile, and logout options
                  <>
                    <MenuItem onClick={() => handleNavigation('/order-history')}>Order History</MenuItem>
                    <MenuItem onClick={() => handleNavigation('/withdraw')}>Withdraw with QR</MenuItem>

                    <MenuItem onClick={() => handleNavigation('/profile')}>Profile</MenuItem>
                    <MenuItem onClick={() => handleNavigationLogout('/')}>Logout</MenuItem>
                  </>
                )}
              </Menu>


            </Box>

            <Box
              onClick={() => navigate('/addtocart')}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  position: 'relative', // Required for badge positioning
                  border: '1px solid black',
                  borderRadius: '50%',
                  background: 'black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  p: { xs: '5px', md: '10px' },
                }}
              >
                <Box
                  component="img"
                  src={cart_icon}
                  alt="cart-icon"
                  sx={{
                    height: { xs: '14px', md: '16px' },
                    width: { xs: '14px', md: '16px' },
                  }}
                />
                {/* Notification Circle */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '3px',
                    right: '0px',
                    backgroundColor: '#C38F51',
                    borderRadius: '50%',
                    width: { xs: '16px', md: '20px' },
                    height: { xs: '16px', md: '20px' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: { xs: '10px', md: '12px' },
                    fontWeight: 'bold',
                    transform: 'translate(50%, -50%)',
                  }}
                >
                  {/* 2 */}
                  {JSON.parse(sessioncustomer_id) !== null ? getCartItemCount?.data?.item_count : cartItemsLocal.length}
                  {/* {cartCount}  */}
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: '12px', md: '14px' },
                    paddingLeft: { xs: '5px', md: '10px' },
                  }}
                >
                  My Cart
                </Typography>
              </Box>
            </Box>
            {isNotificationShow && (

              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack spacing={4} direction="row">

                    <Badge
                      onClick={handleClickNotification}
                      background="black"
                      badgeContent={notificationData?.length}
                      sx={{
                        ".MuiBadge-badge": { color: "white", background: "black" },
                      }}
                    // badgeContent={3}
                    >
                      <MdNotificationsNone
                        style={{ color: "#C38F51", fontSize: "30px" }}
                      />
                    </Badge>
                  </Stack>
                </Box>
                <Notification open={anchorElNotification} onClose={handleCloseNotification} notifications={notificationData} />
              </>
            )}
          </Box>


        </Box >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // paddingTop: "10px",
            // paddingBottom: "10px",
            // background: "#00675B",

            background: "white",
            boxShadow: " 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0)",
            // borderBottom: "1px solid #989898",
          }}
        >
          {/* Logo */}
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={logo_image} alt="Logo" height="30" />
          </Typography> */}

          {/* Desktop Navigation */}
          <Grid
            container
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {filteredNavItems.map((item) => (

              <Grid item key={item.path}>
                <ListItem
                  component={Link}
                  to={item.path}
                  // className={`item ${location.pathname === item.path ? "active" : ""}`}
                  sx={{
                    textTransform: "none",
                    textDecoration: "none",
                    fontWeight: "500",

                    fontSize: "17px",
                    marginRight: "20px",
                    color: location.pathname === item.path ? "#C38F51" : "#252525",
                    borderRadius: 1,

                    "&:hover": {
                      color: "#C38F51",
                    },
                  }}
                >
                  {item.label}
                </ListItem>

              </Grid>
            ))}
          </Grid>

          {/* Mobile Menu Icon */}

          <IconButton
            edge="end"
            color="black"
            // alignItems="end"
            aria-label="menu"
            // sx={{ display: { xs: "block", md: "none" } }}

            sx={{ display: { xs: "block", md: "none" }, ml: "auto" }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          {/* Mobile Drawer */}
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                {filteredNavItems.map((item) => (
                  <ListItem key={item.path} disablePadding>
                    <ListItemButton component={Link} to={item.path}>
                      <ListItemText
                        primary={item.label}
                        sx={{
                          color: location.pathname === item.path ? "#ffaa17" : "black",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>

          </Drawer>
        </Toolbar>
      </AppBar >
    </>
  );
};

export default MyAppBar;
