// import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
// import React from 'react'

// const OfferSection = () => {
//     return (
//         <div>

//             <Box>
//                 <Typography
//                     variant="h4"
//                     align="center"
//                     gutterBottom
//                     sx={{ fontWeight: "bold" }}
//                 >
//                     What We’re Offering
//                 </Typography>
//                 <Grid container spacing={3}>
//                     {[1, 2, 3, 4].map((offer) => (
//                         <Grid item xs={12} sm={6} md={3} key={offer}>
//                             <Card
//                                 sx={{
//                                     textAlign: "center",
//                                     boxShadow: 2,
//                                     "&:hover": { boxShadow: 4 },
//                                 }}
//                             >
//                                 <CardContent>
//                                     <Box
//                                         sx={{
//                                             width: 50,
//                                             height: 50,
//                                             mx: "auto",
//                                             backgroundColor: "primary.main",
//                                             color: "white",
//                                             borderRadius: "50%",
//                                             display: "flex",
//                                             alignItems: "center",
//                                             justifyContent: "center",
//                                             mb: 2,
//                                         }}
//                                     >
//                                         <Typography variant="h6">{offer}</Typography>
//                                     </Box>
//                                     <Typography variant="h6">Lorem Ipsum</Typography>
//                                     <Typography variant="body2">
//                                         Lorem ipsum is are many variations of passages of majority.
//                                     </Typography>
//                                 </CardContent>
//                             </Card>
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Box>
//         </div>
//     )
// }

// export default OfferSection


import React from "react";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import { GrSystem } from "react-icons/gr";
import upload_img from "../../../Assets/upload_img.svg"
import { adminUrl } from "../../../constants";


const OfferSection = ({ offersDates }) => {
  const steps = offersDates?.[0]?.additional_data?.card_design || [
    {
      // id: "1",
      logo: upload_img,

      title: "Heading 1",
      content: "There are many variations of passages of Lorem Ipsum majority have suffered There are many variations of passages of Lorem Ipsum majority have suffered.."
    },
    {
      // id: "2",
      logo: upload_img,


      title: "Heading 2",
      content: "There are many variations of passages of Lorem Ipsum majority have suffered There are many variations of passages of Lorem Ipsum majority have suffered.."
    },
    {
      // id: "3",
      logo: upload_img,


      title: "Heading 3",
      content: "There are many variations of passages There are many variations of passages of Lorem Ipsum majority have suffered. "
    },
    {
      // id: "4",
      logo: upload_img,

      title: "Heading 4",
      content: "There are many variations of passages of Lorem Ipsum majority have suffered There are many variations of passages of Lorem Ipsum majority have suffered.."
    },
    {
      // id: "4",
      logo: upload_img,

      title: "Heading 4",
      content: "There are many variations of passages of Lorem Ipsum majority have suffered There are many variations of passages of Lorem Ipsum majority have suffered.."
    },
    {
      // id: "4",
      logo: upload_img,

      title: "Heading 4",
      content: "There are many variations of passages of Lorem Ipsum majority have suffered There are many variations of passages of Lorem Ipsum majority have suffered.."
    },
  ];
  return (
    <Box sx={{ px:{xs:2,md:4},pt:4 }}>
      <Typography variant="h3" align="center" gutterBottom >
        {offersDates?.[0]?.heading || "Default Heading"}<span style={{ fontWeight: "800" }}> {offersDates?.[0]?.sub_heading || "Default Sub-heading"}</span>
      </Typography>
      <Typography
        sx={{
          paddingBottom: "5px",
          maxWidth: "800px",
          margin: "0 auto",
          textAlign: "center",
          color: "#b0aaaa",



        }}
      >
        {offersDates?.[0]?.content || "Default Content"}

      </Typography>

      <Grid container spacing={4}
        sx={{ p: { xs: 0, md: 4 } }}
      >
        {steps.map((steps) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={steps.id}
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Card
              sx={{
                boxShadow: "none",
                // height: "200px",
                width: "330px",
                backgroundColor: "#F7F7F7",
                '&:hover .icon-box': {
                  backgroundColor: "black",
                  border: "1px solid black" // Change icon background color when card is hovered
                },
                '&:hover .icon-box1': {
                  color: "black", // Change icon background color when card is hovered
                }
              }}
            >
              <CardContent p={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    mb: 2,
                    fontSize: "50px",
                  }}
                >
                  <Box
                    className="icon-box"
                    sx={{
                      border: '1px solid #C38F51',
                      background: '#C38F51',
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      mr: 1,
                      transition: "background-color 0.3s ease", // Smooth transition for icon background
                    }}
                  >
                    <img
                      src={`${adminUrl}${steps.logo}`}
                      style={{ padding: "14px", width: "20px", height: "20px" }}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    color="#C38F51"
                    className="icon-box1"
                    sx={{
                      transition: "background-color 0.3s ease", // Smooth transition for icon background

                    }}

                  >
                    {steps.title}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  // textAlign="justify"
                  sx={{
                    minWidth: "100px",
                    color: "#7A7A7A",
                  }}
                >
                  {steps.content}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}

      </Grid>
    </Box>
  );
};

export default OfferSection;
