

import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Container, Stack, IconButton, Grid } from "@mui/material";
import { FaShareAlt } from "react-icons/fa";
import refer_img from "../../../Assets/refer_img.svg";
import facebook_icon from "../../../Assets/facebook_icon.svg";
import whatsapp_icon from "../../../Assets/whatsapp_icon.svg";
import instagram_icon from "../../../Assets/instagram_icon.svg";
import linkedin_icon from "../../../Assets/linkedin_icon.svg";
import twitter_icon from "../../../Assets/twitter_icon.svg";
import { adminUrl } from "../../../constants";
import refer_img_background from "../../../Assets/referrals_img_background.svg";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidCopyAlt } from "react-icons/bi";
import actions from "../../../ReduxStore/actions";


const InviteFriends = ({ referralsDates, targetRef }) => {
    const [copied, setCopied] = useState(false);
    // const textToCopy = "This is the text to copy!";

    const [showIcons, setShowIcons] = useState(false);
    const { customerLogin } = useSelector((state) => state?.customerLogin);

    const { getCustomerById } = useSelector((state) => state?.getCustomerById);
    
    console.log(getCustomerById, "getCustomerById")
    const sessioncustomer_id = sessionStorage.getItem("sessioncustomer_id");
    console.log(sessioncustomer_id, "sessioncustomer_id")

    const dispatch = useDispatch()
    useEffect(() => {
        if (sessioncustomer_id !=="null" && sessioncustomer_id !== null) {
            const fetchData = async () => {
                const data1 = {
                    data: {},
                    method: "get",
                    apiName: `getCustomerById/${sessioncustomer_id}`,
                };
                await dispatch(actions.GETCUSTOMERBYID(data1));
            };
            fetchData();
        }
    }, [dispatch]);



    const handleButtonClick = () => {
        setShowIcons(!showIcons); // Toggle the visibility of social media icons
    };

    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopied(true);

            // Reset copied status after 2 seconds
            setTimeout(() => setCopied(false), 2000);
        } catch (error) {
            console.error("Failed to copy text: ", error);
        }
    };

    return (
        <Box
            id="referpage"
            ref={targetRef}
            sx={{
                position: "relative", // This is key to make the image positioned relative to this container
                background: "#F7F7F7",
                overflow: "hidden",
                padding: { xs: "50px 0px" },
            }}
        >
            <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                <Grid container spacing={4} alignItems="center">
                    {/* Left Section */}
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography
                                variant="h2"
                                textAlign="start"
                                sx={{
                                    color: "#3B4056",
                                    mb: 2,
                                }}
                            >
                                {referralsDates?.[0]?.heading || "Default Heading"}
                            </Typography>
                            <Typography
                                variant="body1"
                                textAlign="start"
                                sx={{
                                    mb: 3,
                                    lineHeight: "1.6",
                                    color: "#7A7A7A",
                                    maxWidth: "500px",
                                }}
                            >
                                {referralsDates?.[0]?.content || "Default Sub-heading"}
                            </Typography>
                            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleCopy(getCustomerById?.data?.referral_code)}
                                    sx={{
                                        textTransform: "none",
                                        color: "#7A7A7A",
                                        padding: "0px 40px",
                                        height: "35px",
                                        borderColor: "white",
                                        borderRadius: "23px",
                                        background: "white",
                                        "&:hover": {
                                            backgroundColor: "white",
                                        },
                                    }}
                                >
                                    {getCustomerById?.data?.referral_code || "No refer code generate"}
                                    &nbsp; &nbsp; <BiSolidCopyAlt size={20}
                                    // style={{
                                    //     color: copied ? "green" : "#7A7A7A", // Green if copied, gray otherwise
                                    //     transition: "color 0.3s ease-in-out", // Smooth transition for color
                                    // }} 
                                    />
                                    {/* Animation for "Copied!" */}
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: "30px",
                                            opacity: copied ? 1 : 0,
                                            transform: copied ? "translateY(0)" : "translateY(10px)",
                                            transition: "all 0.3s ease-in-out",
                                            color: "green",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Copied!
                                    </div>
                                    {/* {referralsDates?.[0]?.additional_data?.[0]?.primary_button_text || "Primary Button"} */}
                                </Button>
                                {!showIcons && (
                                    <Button
                                        variant="contained"
                                        startIcon={<FaShareAlt style={{ color: "white", fontSize: "14px" }} />}
                                        onClick={handleButtonClick}
                                        sx={{
                                            textTransform: "none",
                                            color: "white",
                                            padding: "0px 30px",
                                            height: "35px",
                                            borderRadius: "23px",
                                            background: "#C38F51",
                                            "&:hover": {
                                                backgroundColor: "#C38F51",
                                            },
                                        }}
                                    >
                                        {referralsDates?.[0]?.additional_data?.[0]?.secondary_button_text || "Secondary Button"}
                                    </Button>
                                )}
                                {showIcons && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 2,
                                        }}
                                    >
                                        {[facebook_icon, whatsapp_icon, instagram_icon, linkedin_icon, twitter_icon].map(
                                            (icon, index) => (
                                                <IconButton
                                                    key={index}
                                                    aria-label={`Social Icon ${index + 1}`}
                                                    sx={{
                                                        backgroundColor: "#C68A48",
                                                        color: "white",
                                                        padding: "12px",
                                                        height: "40px",
                                                        width: "40px",
                                                        "&:hover": {
                                                            backgroundColor: "#D9A766",
                                                        },
                                                    }}
                                                >
                                                    <img src={icon} alt={`Social Icon ${index + 1}`} />
                                                </IconButton>
                                            )
                                        )}
                                    </Box>
                                )}
                            </Stack>
                        </Box>
                    </Grid>

                    {/* Right Section */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                backgroundImage: `url(${refer_img_background})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                height: "350px",
                                // left:"200px",
                                left: "40%",

                                width: "100%",
                                display: { xs: "none", sm: "block" },
                                position: "relative",
                                zIndex: 0,
                            }}
                        >
                            <img
                                src={`${adminUrl}/${referralsDates?.[0]?.additional_data?.[0]?.banner}`}
                                alt="Banner"
                                style={{
                                    position: "absolute", // Make it relative to the parent `Box`
                                    top: "10%", // Adjust as needed
                                    left: "10%", // Adjust as needed
                                    height: "270px",
                                    zIndex: 1,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>

    );
};

export default InviteFriends;
