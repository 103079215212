

import React from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import logo_img from "../../Assets/new_logo.png";
import { Link } from "react-router-dom";
import mail_icon from "../../Assets/call_icon.svg";
import { IconButton, Fab } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter"; // Use this as X
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import facebook_icon from "../../Assets/facebook_icon.svg";
import whatsapp_icon from "../../Assets/whatsapp_icon.svg";
import instagram_icon from "../../Assets/instagram_icon.svg";
import linkedin_icon from "../../Assets/linkedin_icon.svg";
import twitter_icon from "../../Assets/twitter_icon.svg";

const Footer = () => {
  const CompanyMenuItems = [
    { label: "Registration", path: "/register" },
    { label: "Referrals", path: "/referrals" },
    { label: "Services", path: "/Services" },
    { label: "About Us", path: "/about-us" },
    { label: "Contact Us", path: "/contactus" },
  ];

  const PolicyMenuItems = [
    { label: "Support", path: "/support" },
    { label: "Privacy Policy", path: "/privacy-policy" },
    { label: "Terms of Use", path: "/terms-of-use" },
    { label: "Help", path: "/help" },
    { label: "Chat", path: "/chat" },
  ];

  return (
    <div>
      {/* Main Footer Section */}
      <Box
        sx={{
          backgroundColor: "#F7F7F7",
          padding: "30px 24px 10px 24px",
          borderTop: "1px solid #ddd",
        }}
      >
        <Grid container spacing={4} justifyContent="space-between">
          {/* Logo and Description */}
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="center" gap={2}>
              <img
                src={logo_img}
                alt="Gold Stake Logo"
                style={{ width: "180px", height: "60px" }}
              />
            </Box>
            <Typography
              variant="subtitle1"
              color="#252525"
              mt={2}
              sx={{ maxWidth: "300px" }}
            >
              Lorem ipsum simply free text dolor sit amet consectetur adipiscing
              elit. Duis non nulla id metus consequat vehicula.
            </Typography>
          </Grid>

          {/* Links Section */}
          <Grid item xs={12} md={5}>
            <Grid container spacing={3}>
              {/* Company Links */}
              <Grid item xs={6} sm={6} md={6}>
                <Typography
                  variant="body2"
                  color="#C38F51"
                  fontWeight="bold"
                  mb={1}
                >
                  Company
                </Typography>
                <Box>
                  {CompanyMenuItems.map((item, index) => (
                    <Typography
                      key={index}
                      variant="subtitle1"
                      color="#252525"
                      sx={{ mt: 1 }}
                    >
                      <Link
                        to={item.path}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {item.label}
                      </Link>
                    </Typography>
                  ))}
                </Box>
              </Grid>
              {/* Policy Links */}
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="body2" color="#C38F51" mb={1}>
                  Policy
                </Typography>
                <Box>
                  {PolicyMenuItems.map((item, index) => (
                    <Typography
                      key={index}
                      variant="subtitle1"
                      color="#252525"
                      sx={{ mt: 1 }}
                    >
                      <Link
                        to={item.path}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {item.label}
                      </Link>
                    </Typography>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Newsletter Section */}
          <Grid item xs={12} md={3}>
            <Typography variant="body2" color="#C38F51">
              Newsletter
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" mt={2}>
              You will be notified when somthing new will be appear.
            </Typography>
            <TextField
              fullWidth
              placeholder="Email Address*"
              variant="outlined"
              size="small"
              sx={{
                mt: 2,
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "14px", // Adjust font size here
                  color: "#666", // Optional: Adjust placeholder color
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      {/* <EmailIcon sx={{ color: "#FFA500" }} /> */}
                      <img src={mail_icon}></img>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <Grid> */}
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                padding: "16px",
                // width:"100%",
                // backgroundColor: "#f5f5f5", // Matches the UI background color
                // borderTop: "2px solid #e0e0e0", // Optional border
              }}
            >
              {/* Social Media Icons */}
              {[
                { icon: facebook_icon, label: "Facebook" },
                { icon: whatsapp_icon, label: "Instagram" },
                { icon: instagram_icon, label: "WhatsApp" },
                { icon: linkedin_icon, label: "LinkedIn" },
                { icon: twitter_icon, label: "X" },
              ].map((item, index) => (
                <IconButton
                  key={index}
                  aria-label={item.label}
                  sx={{
                    backgroundColor: "#C68A48",
                    color: "white",
                    padding: "12px",
                    height: "40px",
                    width: "40px",
                    "&:hover": {
                      backgroundColor: "#D9A766",
                    },
                  }}
                >
                  {/* {item.icon} */}
                  <img src={item.icon}></img>
                </IconButton>
              ))}

              {/* Scroll to Top Button */}
              {/* <Fab
                                    size="small"
                                    aria-label="scroll to top"
                                    onClick={handleScrollToTop}
                                    sx={{
                                        position: "absolute",
                                        bottom: -20,
                                        right: -25,
                                        top:-30,
                                        borderRadius:"10px",
                                        boxShadow:"none",
                                        transform: "translateX(50%)",
                                        backgroundColor: "white",
                                        color: "#C38F51",
                                        "&:hover": {
                                            backgroundColor: "black",
                                            color:"white"
                                        },
                                    }}
                                >
                                    <KeyboardArrowUpIcon />
                                </Fab> */}
            </Box>

            {/* </Grid> */}
          </Grid>
        </Grid>
      </Box>

      {/* Footer Bottom Section */}
      <Box sx={{ backgroundColor: "#C38F51", py: 2 }}>
        <Typography variant="subtitle1" color="white" align="center">
          Copyright ©{" "}
          <span style={{ color: "black" }}>{new Date().getFullYear()}</span> |
          All Rights Reserved.
        </Typography>
      </Box>
    </div>
  );
};

export default Footer;
