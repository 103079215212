import { createSlice } from '@reduxjs/toolkit';

const CART_STORAGE_KEY = 'cart';

// Helper Functions to Interact with Local Storage
const getCartFromLocalStorage = () => {
  const storedCart = localStorage.getItem(CART_STORAGE_KEY);
  return storedCart ? JSON.parse(storedCart) : [];
};

const saveCartToLocalStorage = (cart) => {
  localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cart));
};

// Initial State
const initialState = {
  cart: getCartFromLocalStorage(),
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    incrementQuantityLocalCart: (state, action) => {
      const item = action.payload;

      // Find if the item exists in the cart
      const existingItem = state.cart.find((cartItem) => cartItem.plan_id === item.plan_id);

      if (existingItem) {
        // Increment the quantity of the existing item
        existingItem.quantity += 1;
      } else {
        // Add new item to the cart with quantity 1
        state.cart.push({ ...item, quantity: 1 });
      }

      // Save to Local Storage
      saveCartToLocalStorage(state.cart);
    },
    decrementQuantityLocalCart: (state, action) => {
      const item = action.payload;

      // Find the item in the cart
      const existingItem = state.cart.find((cartItem) => cartItem.plan_id === item.plan_id);

      if (existingItem) {
        if (existingItem.quantity > 1) {
          // Decrement the quantity
          existingItem.quantity -= 1;
        } else {
          // Remove the item from the cart if quantity is 0 or less
          state.cart = state.cart.filter((cartItem) => cartItem.plan_id !== item.plan_id);
        }

        // Save to Local Storage
        saveCartToLocalStorage(state.cart);
      }
    },
    clearCartLocalCart: (state) => {
      // Clear the cart
      state.cart = [];

      // Save to Local Storage
      saveCartToLocalStorage(state.cart);
    },
    loadCartFromLocalStorage: (state) => {
      state.cart = getCartFromLocalStorage();
    },
  },
});

export const { incrementQuantityLocalCart, decrementQuantityLocalCart,clearCartLocalCart,loadCartFromLocalStorage } =
  cartSlice.actions;

export default cartSlice.reducer;
