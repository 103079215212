








/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { defaultReject, defaultState } from "../../../constants";
import { fetchData } from "../../helpers";
// PublishPost
// PUBLISHPOST
const GETALLCOUNTRYNAMES= createAsyncThunk(
  "getAllCountryNames/getAllCountryNames",
  // eslint-disable-next-line default-param-last
  async (
    // eslint-disable-next-line default-param-last
    payload = {},
    { rejectWithValue }
  ) => {
    try {
      const data = await fetchData(
        payload?.data,
        payload?.method,
        payload?.apiName
      );
      return {
        ...defaultState.List,
        message: data?.data.Message,
        data: data?.data?.data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject.List,
        message: error.message,
      });
    }
  }
);

// PublishPostSlice
const getAllCountryNamesSlice = createSlice({
  name: "getAllCountryNamesSlice",
  initialState: {
    getAllCountryNames: {
      ...defaultState.List,
      loading: false, 
      error: false, 
    },
  },
  reducers: {
    setInitialStateOfgetAllCountryNames: (state) => {
        state.getAllCountryNames.loading = false
        state.getAllCountryNames.error = false
        state.getAllCountryNames.data = []
        state.getAllCountryNames.message = ''
      }
  },
  extraReducers: (builder) => {
    builder.addCase(GETALLCOUNTRYNAMES.fulfilled, (state, action) => {
      state.getAllCountryNames = {
        ...state.getAllCountryNames,
        loading: false,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(GETALLCOUNTRYNAMES.pending, (state, action) => {
      state.getAllCountryNames = {
        ...state.getAllCountryNames,
        loading: true,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(GETALLCOUNTRYNAMES.rejected, (state, action) => {
      state.getAllCountryNames = {
        ...state.getAllCountryNames,
        loading: false,
        error: true,
        ...action.payload,
      };
    });
  },
});

const getAllCountryNamesAction = {
    GETALLCOUNTRYNAMES,
};

export { getAllCountryNamesAction };
export default getAllCountryNamesSlice.reducer;
export const {setInitialStateOfgetAllCountryNames} = getAllCountryNamesSlice.actions
