



// import React from "react";
// import { Box, Typography, Grid } from "@mui/material";
// import StorefrontIcon from "@mui/icons-material/Storefront";
// import styled from "@emotion/styled";
// import ProsperityCard from "../../../Components/ProsperityCard/ProsperityCard";
// import { CardContent, Card, Divider } from "@mui/material";

// const DashedArrow = styled("div")(() => ({
//     position: "absolute",
//     borderTop: "2px dashed black",
//     width: "calc(33% - 40px)", // Adjust the width for proper spacing
//     top: "50%",
//     transform: "translateY(-50%)",
//     left: "calc(33% - 20px)", // Adjust for spacing between cards
//     "&:after": {
//         content: '""',
//         position: "absolute",
//         top: "-6px",
//         right: "-12px",
//         width: "0",
//         height: "0",
//         borderLeft: "8px solid transparent",
//         borderRight: "8px solid transparent",
//         borderTop: "8px solid black",
//     },
// }));

// const HowItWorks = () => {
//     const steps = [
//         {
//             title: "Lorem Ipsum",
//             description:
//                 "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
//             icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
//         },
//         {
//             title: "Lorem Ipsum",
//             description:
//                 "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
//             icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
//         },
//         {
//             title: "Lorem Ipsum",
//             description:
//                 "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
//             icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
//         },
//     ];

//     return (
//         <>
//             <Box sx={{ p: 4 }}>
//                 <Box sx={{ textAlign: "center", mb: 2 }}>
//                     <Typography
//                         variant="h3"
//                         gutterBottom
//                         sx={{ paddingBottom: "5px", fontSize: "30px" }}
//                     >
//                         <span style={{ color: "black", fontWeight: "800" }}>Three</span> Steps For Prosperity
//                     </Typography>
//                     <Typography
//                     variant="body1"
//                         sx={{
//                             // paddingBottom: "5px",
//                             maxWidth: "800px",
//                             margin: "0 auto",
//                             textAlign: "center",
//                             color: "#b0aaaa",

//                         }}
//                     >
//                         Lorem Ipsum is simply dummy text of the printing and typesetting industry.
//                         Lorem Ipsum has been the industry’s standard dummy text ever since the
//                         1500s, when an unknown printer took a galley of type and scrambled it to
//                         make a type specimen book.
//                     </Typography>
//                 </Box>


//                 <Box
//                     sx={{
//                         display: "flex",
//                         flexDirection: "column",
//                         alignItems: "center",
//                         // mt: 2,
//                     }}
//                 >
//                     <Typography variant="body2" sx={{ mb: 2, color: "#C38F51" }}>Double Layered Benefits On Our Exclusive Referral Plan</Typography>
//                     {/* First Card */}
//                     <Card
//                         sx={{
//                             width: "500px",
//                             backgroundColor: "#F7F7F7",
//                             textAlign: "center",
//                             boxShadow: "none",

//                             mb: 2,
//                         }}
//                         elevation={2}
//                     >
//                         <CardContent>
//                             <Typography variant="body2" sx={{color: "#C38F51", mb: 1 }}>
//                                 Diamond Referrer
//                             </Typography>
//                             <Typography variant="body1" sx={{ fontSize: "14px", color: "#7A7A7A" }}>
//                                 You will be a Diamond Referrer staked with Diamond Referrer worth
//                                 of Gold for every 1000 USD Purchase by your referral.
//                             </Typography>
//                         </CardContent>
//                     </Card>

//                     {/* Dashed Line */}
//                     <Divider
//                         orientation="vertical"
//                         flexItem
//                         sx={{
//                             borderLeftWidth: "2px",
//                             borderColor: "#ccc",
//                             height: "50px",
//                             mb: 2,
//                         }}
//                     />

//                     {/* Second Card */}
//                     <Card
//                         sx={{
//                             width: "500px",
//                             backgroundColor: "#F7F7F7",
//                             // border: "2px solid #2196f3",
//                             textAlign: "center",
//                             boxShadow: "none",

//                             mb: 2,
//                         }}
//                         elevation={4}
//                     >
//                         <CardContent>
//                             {/* <Typography variant="h6" sx={{ fontWeight: "bold" }}> */}
//                             <Typography  variant="body2" sx={{ color: "#C38F51", mb: 1 }}>

//                                 Diamond Referrer
//                             </Typography>
//                             {/* <Typography variant="body2"> */}
//                             <Typography variant="body1" sx={{ color: "#7A7A7A" }}>

//                                 Your Referral will be a Diamond Referrer staked with Diamond
//                                 Referrer worth of Gold for every 1000 USD Purchase by your
//                                 referral's referral.
//                             </Typography>
//                         </CardContent>
//                     </Card>

//                     {/* Dashed Line */}
//                     <Divider
//                         orientation="vertical"
//                         flexItem
//                         sx={{
//                             borderLeftWidth: "2px",
//                             borderColor: "red",
//                             height: "50px",
//                             mb: 2,
//                         }}
//                     />

//                     {/* Third Card */}
//                     <Card
//                         sx={{
//                             width: "500px",
//                             backgroundColor: "#F7F7F7",
//                             textAlign: "center",
//                             boxShadow: "none",
//                             mb: 2,
//                         }}
//                         elevation={2}
//                     >
//                         <CardContent>
//                             {/* <Typography variant="h6" sx={{ fontWeight: "bold" }}> */}
//                             <Typography variant="body2" sx={{color: "#C38F51", mb: 1 }}>

//                                 Ruby Referrer
//                             </Typography>
//                             {/* <Typography variant="body2"> */}
//                             <Typography variant="body1" sx={{ color: "#7A7A7A" }}>

//                                 You will be a Ruby Referrer staked with Ruby Referrer worth of Gold
//                                 for every 1000 USD Purchase by your referral’s referral.
//                             </Typography>
//                         </CardContent>
//                     </Card>
//                 </Box>


//                 {/* Add the dashed arrows between the cards */}
//                 {/* {steps.slice(0, -1).map((_, index) => (
//             <DashedArrow key={index} style={{ left: `${(index + 1) * 33.33}%` }} />
//           ))} */}
//                 {/* </Box> */}
//             </Box>
//         </>
//     );
// };

// export default HowItWorks;








import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import styled from "@emotion/styled";
import ProsperityCard from "../../../Components/ProsperityCard/ProsperityCard";
import { CardContent, Card, Divider } from "@mui/material";
import image from "../../../Assets/instagram_icon.svg"
import { adminUrl } from "../../../constants";

const DashedArrow = styled("div")(() => ({
    position: "absolute",
    borderTop: "2px dashed black",
    width: "calc(33% - 40px)", // Adjust the width for proper spacing
    top: "50%",
    transform: "translateY(-50%)",
    left: "calc(33% - 20px)", // Adjust for spacing between cards
    "&:after": {
        content: '""',
        position: "absolute",
        top: "-6px",
        right: "-12px",
        width: "0",
        height: "0",
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        borderTop: "8px solid black",
    },
}));



const HowItWorks = ({ howItWorksDates }) => {
    const steps = [
        {
            title: "Lorem Ipsum",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
            icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
        },
        {
            title: "Lorem Ipsum",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
            icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
        },
        {
            title: "Lorem Ipsum",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
            icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
        },
    ];

    console.log(howItWorksDates, "howItWorksDateshowItWorksDates")

    const tiers = howItWorksDates[0]?.additional_data?.card_design?.map((data, index) => ({
        tier_level: index + 1,
        tier_name: data?.title,
        line1: data?.card_content_1,
        line2: data?.card_content_2,
        img: data?.logo,
    }));

    console.log(tiers, "tierstierstiers");



    const tiers1 = [
        {
            tier_level: 1,
            tier_name: "Diamond Referral",
            line1: "you will be a Diamond refferrer staked with Diamond Refferrer",
            line2: "worth of Gold for every 1000 USD Purchase by your referral",
            img: image,
            // img: "http://localhost:5000/api/v1/uploads/images/uploadFile-1739266686946.png",
        },
        {
            tier_level: 2,
            tier_name: "Ruby Referral",
            line1: "you will be a Diamond refferrer staked with Diamond Refferrer",
            line2: "worth of Gold for every 1000 USD Purchase by your referral",
            img: image,

            // img: "http://localhost:5000/api/v1/uploads/images/uploadFile-1739266686946.png",
        },
        {
            tier_level: 3,
            tier_name: "Gold Referral",
            line1: "you will be a Ruby refferrer staked with Diamond Refferrer",
            line2: "worth of Gold for every 1000 USD Purchase by your referral",
            img: image,

            // img: "http://localhost:5000/api/v1/uploads/images/uploadFile-1739266686946.png",
        },
        // {
        //     tier_level: 4,
        //     tier_name: "Gold Referral",
        //     description: "Earn 10+ referrals",
        //     img: "http://localhost:5000/api/v1/uploads/images/uploadFile-1739266686946.png",
        // }
    ];


    const boxWidth = 500;
    const boxHeight = 100;
    const startX = 300;
    const startY = 20;
    const gap = 70;
    const shiftStep = 80;
    const leftLineX = startX - 50;
    return (
        <>
            <Box sx={{ p: 4 }}>
                <Box sx={{ textAlign: "center", mb: 2 }}>
                    <Typography
                        variant="h3"
                        gutterBottom
                        sx={{ paddingBottom: "5px", fontSize: "30px" }}
                    >
                        <span style={{ color: "black", fontWeight: "800" }}>
                            {howItWorksDates[0]?.heading || "Default Heading"}

                        </span>
                        &nbsp;{howItWorksDates[0]?.sub_heading || "Default Sub-Heading"}

                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            // paddingBottom: "5px",
                            maxWidth: "800px",
                            margin: "0 auto",
                            textAlign: "center",
                            color: "#b0aaaa",

                        }}
                    >
                        {howItWorksDates[0]?.content || "Default Content"}

                    </Typography>
                </Box>



                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <svg width="100%" height={startY + tiers?.length * (boxHeight + gap)}>
                        {tiers?.map((tier, index) => {
                            const x = startX + index * shiftStep;
                            const y = startY + index * (boxHeight + gap);
                            const imgSize = 30;
                            const imgX = x + 25;
                            const imgY = y + 30;

                            return (
                                <g key={tier?.tier_level}>
                                    {/* Box */}
                                    <rect
                                        x={x}
                                        y={y}
                                        width={boxWidth}
                                        height={boxHeight}
                                        rx="50"
                                        fill="none"
                                        stroke="#C38F51"
                                        strokeWidth="2"
                                    />

                                    {/* Red Border Circle */}
                                    <circle cx={imgX + imgSize / 2} cy={imgY + imgSize / 2} r={imgSize / 2 + 7} fill="#f1cea7" />

                                    {/* Clip path for rounded image */}
                                    <defs>
                                        <clipPath id={`clip-${tier?.tier_level}`}>
                                            <circle cx={imgX + imgSize / 2} cy={imgY + imgSize / 2} r={imgSize / 2} />
                                        </clipPath>
                                    </defs>

                                    {/* Image with border-radius */}
                                    <image
                                        href={`${adminUrl}${tier?.img}`}
                                        x={imgX}
                                        y={imgY}
                                        width={imgSize}
                                        height={imgSize}
                                        clipPath={`url(#clip-${tier?.tier_level})`}
                                    />

                                    {/* Tier Name */}
                                    <text x={x + 75} y={y + 35} fontSize="16" fontWeight="bold" fill="#C38F51">
                                        {tier?.tier_name}
                                    </text>

                                    {/* Description */}
                                    <text x={x + 75} y={y + 55} fontSize="14" fill="gray">
                                        {tier?.line1}
                                    </text>

                                    {/* Description */}
                                    <text x={x + 75} y={y + 75} fontSize="14" fill="gray">
                                        {tier?.line2}
                                    </text>

                                    {/* Vertical Line & Arrow */}
                                    {index < tiers?.length - 1 && (
                                        <>
                                            <circle cx={x + boxWidth / 2} cy={y + boxHeight} r="5" fill="#C38F51" />
                                            <line
                                                x1={x + boxWidth / 2}
                                                y1={y + boxHeight}
                                                x2={x + boxWidth / 2}
                                                y2={y + boxHeight + gap - 5}
                                                stroke="#C38F51"
                                                strokeWidth="2"
                                            />
                                            <polygon
                                                points={`${x + boxWidth / 2 - 5},${y + boxHeight + gap - 10} 
                            ${x + boxWidth / 2 + 5},${y + boxHeight + gap - 10} 
                            ${x + boxWidth / 2},${y + boxHeight + gap}`}
                                                fill="#C38F51"
                                            />
                                        </>
                                    )}
                                </g>
                            );
                        })}

                        {/* Left-side connecting line */}
                        <circle cx={startX} cy={startY + boxHeight / 2} r="5" fill="#C38F51" />
                        <line x1={startX} y1={startY + boxHeight / 2} x2={leftLineX} y2={startY + boxHeight / 2} stroke="#C38F51" strokeWidth="2" />
                        <line x1={leftLineX} y1={startY + boxHeight / 2} x2={leftLineX} y2={startY + (tiers?.length - 1) * (boxHeight + gap) + boxHeight / 2} stroke="#C38F51" strokeWidth="2" />
                        <line x1={leftLineX} y1={startY + (tiers?.length - 1) * (boxHeight + gap) + boxHeight / 2} x2={startX + (tiers?.length - 1) * shiftStep} y2={startY + (tiers?.length - 1) * (boxHeight + gap) + boxHeight / 2} stroke="#C38F51" strokeWidth="2" />

                        {/* Left Arrowhead */}
                        <polygon
                            points={`${startX + (tiers?.length - 1) * shiftStep - 10},${startY + (tiers?.length - 1) * (boxHeight + gap) + boxHeight / 2 - 5} 
                  ${startX + (tiers?.length - 1) * shiftStep - 10},${startY + (tiers?.length - 1) * (boxHeight + gap) + boxHeight / 2 + 5} 
                  ${startX + (tiers?.length - 1) * shiftStep},${startY + (tiers?.length - 1) * (boxHeight + gap) + boxHeight / 2}`}
                            fill="#C38F51"
                        />
                    </svg>
                </div>




                {/* Add the dashed arrows between the cards */}
                {/* {steps.slice(0, -1).map((_, index) => (
            <DashedArrow key={index} style={{ left: `${(index + 1) * 33.33}%` }} />
          ))} */}
                {/* </Box> */}
            </Box>
        </>
    );
};

export default HowItWorks;


