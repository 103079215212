



// import React from 'react';
// import ReferralsMainPage from '../ReferralsMainPage/ReferralsMainPage';
// import Refer_EarnPage from '../Refer_EarnPage/Refer_EarnPage';
// import InviteFriends from '../InviteFriends/InviteFriend';
// import HowItWorks from '../HowItWorks/HowItWorks';


// const ReferralHome = () => {
//     const handleScrollToTop = () => {
//         window.scrollTo({ top: 0, behavior: "smooth" });
//     };
//     return (

//         <div style={{ position: "relative" }}>
//             {/* <MyAppBar /> */}

//             <ReferralsMainPage />
//             <Refer_EarnPage />
//             <InviteFriends />
//             <HowItWorks />

//             {/* <Pusrchase_GoldStake />
//       <ReferSection />
//       <PurchaseSchemes /> */}

//             {/* <Footer /> */}

//             {/* <Fab
//         size="small"
//         aria-label="scroll to top"
//         onClick={handleScrollToTop}
//         sx={{
//           position: "sticky",
//           bottom: 0,
//           right: 0,
//           // top: -30,
//           borderRadius: "10px",
//           boxShadow: "none",
//           transform: "translateX(50%)",
//           backgroundColor: "red",
//           color: "#C38F51",
//           "&:hover": {
//             backgroundColor: "black",
//             color: "white"
//           },
//         }}
//       >
//         <KeyboardArrowUpIcon />
//       </Fab> */}

//         </div>
//     )
// }


// export default ReferralHome;








import React, { useEffect, useRef, useState } from 'react';
import ReferralsMainPage from '../ReferralsMainPage/ReferralsMainPage';
import Refer_EarnPage from '../Refer_EarnPage/Refer_EarnPage';
import InviteFriends from '../InviteFriends/InviteFriend';
import HowItWorks from '../HowItWorks/HowItWorks';
import actions from "../../../ReduxStore/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Box } from '@mui/material';

const ReferralHome = () => {
  const [loading, setLoading] = useState(true);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const dispatch = useDispatch();

  const { getAllReferralsSections } = useSelector((state) => state?.getAllReferralsSections);

  console.log(getAllReferralsSections);


  useEffect(() => {



    const fetchData = async () => {
      const data1 = { data: {}, method: "get", apiName: "getAllReferralSectionsApproved" };



      setLoading(true); // Set loading to true when the request starts
      await dispatch(actions.GETALLREFERRALSSECTIONS(data1));
      setLoading(false); // Set loading to false after data is fetched
    };
    fetchData();
  }, [dispatch]);


  // Function to get dates by section_name
  const getDatesBySectionName = (sectionName) => {
    return getAllReferralsSections?.data
      ?.filter((section) => section.section_name === sectionName)
      || [];
  };


  const bannerDates = getAllReferralsSections?.data
    ? getDatesBySectionName('banner')
    : [];

  const threeStepsDates = getAllReferralsSections?.data
    ? getDatesBySectionName('threeSteps')
    : [];



  const referralsDates = getAllReferralsSections?.data
    ? getDatesBySectionName('referrals')
    : [];

  const howItWorksDates = getAllReferralsSections?.data
    ? getDatesBySectionName('howItsWork')
    : [];


  const targetRef = useRef(null); // Create a reference for the target element

  const handleScrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };




  return (

    <div style={{ position: "relative" }}>
      {/* <MyAppBar /> */}
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          style={{ background: "#fff", zIndex: 10 }}
        >
          <CircularProgress style={{ color: 'gold' }} /> {/* Gold spinner */}
        </Box>
      ) : (
        <>
          <ReferralsMainPage bannerDates={bannerDates} onScrollToTarget={handleScrollToTarget} />
          <Refer_EarnPage threeStepsDates={threeStepsDates} />
          <InviteFriends referralsDates={referralsDates} targetRef={targetRef} />
          <HowItWorks howItWorksDates={howItWorksDates}/>
        </>
      )}
      {/* <Pusrchase_GoldStake />
      <ReferSection />
      <PurchaseSchemes /> */}

      {/* <Footer /> */}

      {/* <Fab
        size="small"
        aria-label="scroll to top"
        onClick={handleScrollToTop}
        sx={{
          position: "sticky",
          bottom: 0,
          right: 0,
          // top: -30,
          borderRadius: "10px",
          boxShadow: "none",
          transform: "translateX(50%)",
          backgroundColor: "red",
          color: "#C38F51",
          "&:hover": {
            backgroundColor: "black",
            color: "white"
          },
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab> */}

    </div>
  )
}


export default ReferralHome;



