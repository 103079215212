
import React from "react";
import { Box, Typography, Icon, Paper } from "@mui/material";
import { clsx } from "clsx";
 
const StatusCard = ({ icon, bgcolor, iconcolor, text, Grams}) => {
  const color = "blue";
  const hov = "red";
 
  return (
    <Paper
      sx={{
        padding: 2,
        backgroundColor: "white",
        borderRadius: 2,
        boxShadow: 3,
 
      }}
    >
 
      <Typography variant="subtitle2" sx={{ fontSize: "14px", color: "#7A7A7A" }} >
        {text}
      </Typography>
      <Typography variant="body2" color="#C38F51" mt={1}>{Grams}
        <span style={{ fontSize: "16px", color: "black", fontWeight: "400" }}> Grams </span>
      </Typography>
    </Paper>
  );
};
 
export default StatusCard;