

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Pagination,
  FormControl,
  InputLabel,
  Select, // Import Pagination component
} from "@mui/material";
import { HiDownload } from "react-icons/hi";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaEye, FaFileDownload } from "react-icons/fa";
import { format } from "date-fns";

const CustomTable = ({ tableData, tableHeadData }) => {
  // const rowsPerPage = 5;
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);

  const filteredCustomers = tableData.filter((customer) =>
    customer.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredCustomers.length / rowsPerPage);
  console.log(totalPages, "totalpagees");
  const paginatedCustomers = filteredCustomers.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  console.log(paginatedCustomers, "paginatedCustomerspaginatedCustomers");

  const handlePageChange = (event, page) => setCurrentPage(page);

  const handleDownload = () => {
    const doc = new jsPDF();
    const headers = Object.keys(tableData[0]);
    const rows = tableData.map((row) => headers.map((header) => row[header]));
    doc.text("Customer Data", 14, 10);
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: 20,
    });
    doc.save("CustomerData.pdf");
  };

  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="end">
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
          <Typography variant="body1" sx={{ marginRight: 1, color: "#7A7A7A" }}>Show per Page</Typography>
          <FormControl>
            {/* <label htmlFor="items-per-page">Items</label> */}
            <select
              style={{ height: "30px", color: "#7A7A7A" }}
              id="items-per-page"
              value={rowsPerPage}
              onChange={handleChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </FormControl>
        </Box>

        <IconButton onClick={handleDownload}>
          <HiDownload />
        </IconButton>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ background: "#C38F51" }}>
              {tableHeadData.map((data, index) => (
                <TableCell
                  key={index}
                  sx={{
                    color: "white",
                    padding: "8px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "left",
                  }}
                >
                  <Typography variant="subtitle2">{data}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCustomers.map((customer, index) => (
              <TableRow
                key={customer.id}
                sx={{
                  backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F7F7F7",
                }}
              >
                <TableCell sx={{ color: "#7A7A7A", padding: "8px" }}>
                  <Typography variant="body1">
                    {(currentPage - 1) * rowsPerPage + index + 1}
                  </Typography>
                </TableCell>
                {Object.keys(customer).map(
                  (key) =>
                    key !== "id" && key !== "purchase_id" && (
                      <TableCell
                        key={key}
                        sx={{
                          color: "#7A7A7A",
                          padding: "8px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "left",
                        }}
                      >
                          <Typography variant="body1">

                            {key === "stake_starting" || key === "stake_maturity"
                              ? customer[key] && !isNaN(new Date(customer[key]))
                                ? format(new Date(customer[key]), "MMMM d, yyyy")
                                : "Invalid Date"
                              : customer[key]}

                          </Typography>
                     
                        {/* <Typography variant='body1'>{customer[key]}</Typography> */}
                      </TableCell>
                    )
                )}
                <TableCell sx={{ padding: "8px" }}>
                  <IconButton onClick={handleMenuOpen} color="black">
                    <BsThreeDotsVertical fontSize="14px" />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleDownload}>
                      <ListItemIcon>
                        <FaFileDownload
                          style={{ fontSize: "16px", color: "#C38F51" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Download Certificate" />
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleMenuClose}>
                      <ListItemIcon>
                        <FaEye style={{ fontSize: "16px", color: "#C38F51" }} />
                      </ListItemIcon>
                      <ListItemText primary="View" />
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Pagination
          count={totalPages} // Use totalPages instead of pageCount
          page={currentPage}
          onChange={handlePageChange}
          siblingCount={1}
          boundaryCount={1}
          shape="rounded"
          sx={{
            mt: 2,
            "& .MuiPaginationItem-root": {
              color: "#C38F51",
              border: "1px solid #C38F51",
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&.Mui-selected": {
                backgroundColor: "#C38F51",
                color: "white",
                border: "none",
                "&:hover": {
                  backgroundColor: "#C38F51",
                },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomTable;
