// import { Box, Grid, Typography, Button } from '@mui/material';
// import React from 'react';
// import { ToastContainer, toast } from 'react-toastify';
// import { Formik, Form, Field } from 'formik';
// import * as Yup from 'yup';
// import CustomInputLogin from '../../Components/CustomInput/CustomInput';

// const WithdrawWithQR = () => {
//     const initialValues = {
//         gold_stake: '',
//     };

//     const validationSchema = Yup.object({
//         gold_stake: Yup.number()
//             .typeError('Gold stake must be a number')
//             .positive('Gold stake must be positive')
//             .required('Gold stake is required'),
//     });

//     const handleSubmit = (values, { resetForm }) => {
//         toast.success('QR Code generated successfully!');
//         console.log('Form Submitted:', values);
//         resetForm();
//     };

//     return (
// <div className="login-container">
//     <Grid container sx={{ boxShadow: 3, height: "100vh" }}>
//         <Grid
//             item
//             xs={12}
//             md={12}
//             sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 p: 4,
//                 background: "#FFFFFF",
//                 width: "100%",
//             }}
//         >
//             <Box
//                 sx={{
//                     width: "100%",
//                     maxWidth: 350,
//                     py: 8,
//                     px: 4,
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     boxShadow: "2px",
//                     background: "#F7F7F7",
//                 }}
//             >
//                 <Typography variant="h4" textAlign="center" sx={{ fontSize: "30px", fontWeight: "600", mb: 4 }}>
//                     Generate QR
//                 </Typography>

//                 <Formik
//                     initialValues={initialValues}
//                     validationSchema={validationSchema}
//                     onSubmit={handleSubmit}
//                 >
//                     {({ errors, touched }) => (
//                         <Form style={{ width: "100%" }}>
//                             <CustomInputLogin
//                                 // component={CustomInputLogin}
//                                 label="Gold Stake (In grams)"
//                                 name="gold_stake"
//                                 inputType="number"
//                                 custPlaceholder="Enter Withdraw Gold In Grams"
//                                 // error={touched.gold_stake && Boolean(errors.gold_stake)}
//                                 // helperText={touched.gold_stake && errors.gold_stake}
//                             />

//                             <Button 
//                                 type="submit" 
//                                 variant="contained" 
//                                 color="primary" 
//                                 sx={{ mt: 3, width: "100%" }}
//                             >
//                                 Generate QR
//                             </Button>
//                         </Form>
//                     )}
//                 </Formik>
//             </Box>
//         </Grid>

//         <ToastContainer position="top-right" autoClose={1500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
//     </Grid>
// </div>
//     );
// };

// export default WithdrawWithQR;


import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
// import { Html5QrcodeScanner } from "html5-qrcode";
import CustomInputLogin from "../../Components/CustomInput/CustomInput";
import { Formik, Form } from "formik";
// import { Form } from "react-router-dom";
import { Box, Button, Grid, Grid2, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from 'yup';
import { adminUrl } from "../../constants";
import { useNavigate } from "react-router-dom";


const WithdrawWithQR = () => {
    const [goldGrams, setGoldGrams] = useState("");
    const [qrCode, setQrCode] = useState("");
    const [message, setMessage] = useState("");
    const [showScanner, setShowScanner] = useState(false);
    const [otp, setOtp] = useState("");
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [scannedQrCode, setScannedQrCode] = useState(""); // Store scanned QR code
    const scannerRef = useRef(null);



    const initialValues = {
        gold_stake: '',
    };

    const validationSchema = Yup.object({
        gold_stake: Yup.number()
            .typeError('Gold stake must be a number')
            .positive('Gold stake must be positive')
            .required('Gold stake is required'),
    });

    const API_URL = "http://localhost:5000/api/v1";

    const sessioncustomer_id = sessionStorage.getItem("sessioncustomer_id");

    const generateQrCode = async (values) => {
        try {
            const response = await axios.post(`${adminUrl}/generate-qr`, {
                customer_id: sessioncustomer_id,
                gold_grams: parseFloat(values?.gold_stake),
            });
            console.log(response, "GoldWithdrawalGoldWithdrawal");
            setQrCode(response.data.qr_code);
            setMessage("QR Code generated successfully.");
            toast.success(response?.data?.message)

        } catch (error) {
            setMessage(error.response?.data?.message || "Error generating QR Code");
            toast.error(error.response?.data?.message)
        }
    };

    // const initiateVerification = async (qr) => {
    //     try {
    //         const response = await axios.post(`${adminUrl}/verify-qr`, {
    //             qr_code: qr,
    //         });

    //         setMessage(response.data.message);

    //         console.log(response.data, "GoldWithdrawalGoldWithdrawal");

    //         setScannedQrCode(qr); // Store QR code for Step 2
    //         setShowOtpInput(true); // Show OTP input after Step 1
    //     } catch (error) {
    //         setMessage(error.response?.data?.message || "Error initiating verification");
    //     }
    // };

    // const completeVerification = async () => {
    //     try {
    //         const response = await axios.post(`${adminUrl}/verify-qr`, {
    //             qr_code: scannedQrCode,
    //             otp: otp,
    //         });

    //         console.log(response.data, "GoldWithdrawalGoldWithdrawal");

    //         setMessage(`Verification successful! Withdrawal Amount: ${response.data.gold_grams} grams`);
    //         setShowOtpInput(false); // Hide OTP input after successful verification
    //     } catch (error) {
    //         setMessage(error.response?.data?.message || "Error verifying OTP");
    //     }
    // };

    // useEffect(() => {
    //     if (showScanner) {
    //         scannerRef.current = new Html5QrcodeScanner("reader", {
    //             fps: 10,
    //             qrbox: 250,
    //         });

    //         scannerRef.current.render(
    //             (decodedText) => {
    //                 setShowScanner(false);
    //                 scannerRef.current.clear();
    //                 initiateVerification(decodedText); // Call Step 1
    //             },
    //             (error) => console.error("QR Scan Error:", error)
    //         );
    //     }

    //     return () => {
    //         if (scannerRef.current) {
    //             scannerRef.current.clear();
    //         }
    //     };
    // }, [showScanner]);

    const navigate = useNavigate()

    return (
        <div style={{ padding: "20px", textAlign: "center" }}>



            <div className="login-container">
                <Grid container sx={{ boxShadow: 3, height: "100vh" }}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            p: 4,
                            background: "#FFFFFF",
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                maxWidth: 350,
                                py: 8,
                                px: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                boxShadow: "2px",
                                background: "#F7F7F7",
                            }}
                        >
                            <Typography variant="h4" textAlign="center" sx={{ fontSize: "30px", fontWeight: "600", mb: 4 }}>
                                Generate QR
                            </Typography>

                            {!qrCode ? (
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => {
                                        console.log(values)
                                        generateQrCode(values)
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form style={{ width: "100%" }}>
                                            <CustomInputLogin
                                                // component={CustomInputLogin}
                                                label="Gold Stake (In grams)"
                                                name="gold_stake"
                                                inputType="number"
                                                custPlaceholder="Enter Withdraw Gold In Grams"
                                            // error={touched.gold_stake && Boolean(errors.gold_stake)}
                                            // helperText={touched.gold_stake && errors.gold_stake}
                                            />

                                            {/* <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                sx={{ mt: 3, width: "100%" }}
                                            >
                                                Generate QR
                                            </Button> */}
                                        
                                            <Button
                                                variant="contained"
                                                type="submit"

                                                // onClick={handleGoRegisterComplete}
                                                // sx={{ textTransform: "none", background: "#C38F51", color: "white", borderRadius: "23px" }}
                                                sx={{
                                                    // backgroundColor: "#C38F51",
                                                    mt: 2,
                                                    textTransform: "none",
                                                    color: "white",
                                                    fontSize: "16px",
                                                    // padding: "0px 40px",
                                                    padding: { xs: "0px 20px", md: "0px 40px" },
                                                    width: "100%",
                                                    minHeight: "40px",
                                                    boxShadow: "none",

                                                    borderRadius: "23px",
                                                    color: "white",
                                                    background: "#C38F51 0% 0% no-repeat padding-box",
                                                    "&:hover": {
                                                        backgroundColor: "#3B4056",
                                                    },
                                                }}
                                            >
                                                Generate QR
                                            </Button>
                                            <Button
                                                variant="contained"
                                                // type="submit"
                                                onClick={() => {
                                                    navigate("/")
                                                }}

                                                // sx={{ textTransform: "none", background: "#C38F51", color: "white", borderRadius: "23px" }}
                                                sx={{
                                                    // backgroundColor: "#C38F51",
                                                    mt: 2,
                                                    textTransform: "none",
                                                    width: "100%",

                                                    color: "white",
                                                    fontSize: "16px",
                                                    // padding: "0px 40px",
                                                    padding: { xs: "0px 20px", md: "0px 40px" },

                                                    minHeight: "40px",
                                                    boxShadow: "none",

                                                    borderRadius: "23px",
                                                    color: "black",
                                                    background: "white 0% 0% no-repeat padding-box",
                                                    "&:hover": {
                                                        backgroundColor: "#3B4056",
                                                        color:"white"
                                                    },
                                                }}
                                            >
                                                Back

                                            </Button>
                                        </Form>
                                    )}
                                </Formik>) : (
                                <>
                                    <div>
                                        <h3>Scan this QR Code</h3>
                                        <img src={qrCode} alt="QR Code" style={{ width: "200px", marginTop: "10px" }} />
                                    </div>

                                    <Button
                                        variant="contained"
                                        type="submit"
                                        onClick={() => {
                                            setQrCode("")
                                        }}
                                        // sx={{ textTransform: "none", background: "#C38F51", color: "white", borderRadius: "23px" }}
                                        sx={{
                                            // backgroundColor: "#C38F51",
                                            width:"100%",
                                            mt: 2,
                                            textTransform: "none",
                                            color: "white",
                                            fontSize: "16px",
                                            // padding: "0px 40px",
                                            padding: { xs: "0px 20px", md: "0px 40px" },

                                            minHeight: "40px",
                                            boxShadow: "none",

                                            borderRadius: "23px",
                                            background: "#C38F51 0% 0% no-repeat padding-box",
                                            "&:hover": {
                                                backgroundColor: "#3B4056",
                                            },
                                        }}
                                    >
                                        Back
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Grid>

                    <ToastContainer position="top-right" autoClose={1500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
                </Grid>
            </div>


        </div>
    );
};

export default WithdrawWithQR;



