


import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const MapContainer = styled(Box)({
    height: "400px",
    width: "100%",
});

const CustomMap = ({ mapLink }) => {
    return (
        <Box p="0 24px 24px 24px">
            <MapContainer>
                {mapLink ? (
                    <iframe
                        title="Google Map"
                        src={mapLink}
                        width="100%"
                        height="100%"
                        style={{ border: "0" }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                ) : (
                    <p>No map link provided</p>
                )}
            </MapContainer>
        </Box>
    );
};

export default CustomMap;
