
// import React from 'react';


// import { IconButton, Fab } from "@mui/material";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import MyAppBar from '../../Components/AppBar/CustomAppBar';
// import BlogMainPage from './BlogMainPage';
// import LatestNewsSection from './LatestNewsSection';
// import NextStepForwardGold from './NextStepForwardGold';
// import RecentPosts from './RecentPosts';


// const BlogsHome = () => {
//   const handleScrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };
//   return (

//     <div style={{ position: "relative" }}>
//       {/* <MyAppBar /> */}

//       {/* <LandingPage /> */}
//       <BlogMainPage/>
//       <LatestNewsSection />
//       <NextStepForwardGold />
//       <RecentPosts />


//       {/* <Footer /> */}



//     </div>
//   )
// }


// export default BlogsHome;





import React, { useEffect, useState } from 'react';


import { IconButton, Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MyAppBar from '../../Components/AppBar/CustomAppBar';
import BlogMainPage from './BlogMainPage';
import LatestNewsSection from './LatestNewsSection';
import NextStepForwardGold from './NextStepForwardGold';
import RecentPosts from './RecentPosts';
import actions from "../../ReduxStore/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Box } from '@mui/material';
import ReadMoreModal from './Modal/ReadMoreModal';


const BlogsHome = () => {
  const [loading, setLoading] = useState(true);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  const dispatch = useDispatch();

  const { getAllBlogSections } = useSelector((state) => state?.getAllBlogSections);

  console.log(getAllBlogSections);


  useEffect(() => {



    const fetchData = async () => {
      const data1 = { data: {}, method: "get", apiName: "getAllBlogSectionsApproved" };



      setLoading(true); // Set loading to true when the request starts
      await dispatch(actions.GETALLBLOGSECTIONS(data1));
      setLoading(false); // Set loading to false after data is fetched
    };
    fetchData();
  }, [dispatch]);


  // Function to get dates by section_name
  const getDatesBySectionName = (sectionName) => {
    return getAllBlogSections?.data
      ?.filter((section) => section.section_name === sectionName)
      || [];
  };


  const bannerDates = getAllBlogSections?.data
    ? getDatesBySectionName('banner')
    : [];

  const latestDates = getAllBlogSections?.data
    ? getDatesBySectionName('latest')
    : [];

  const nextStepDates = getAllBlogSections?.data
    ? getDatesBySectionName('nextStep')
    : [];

  const recentPostDates = getAllBlogSections?.data
    ? getDatesBySectionName('recentPost')
    : [];


  const [isReadMoreModalOpen, setReadMoreModalOpen] = useState(false);
  const [readMoreModalContent, setReadMoreModalContent] = useState([]);
  

  const handleReadMoreOpenModal = (value) => {
    console.log(value,"valuesssssssssssss")
    setReadMoreModalOpen(true);
    setReadMoreModalContent(value)
  };

  const handleReadMoreCloseModal = () => {
    setReadMoreModalOpen(false);
    
  };


  return (

    <div style={{ position: "relative" }}>
      {/* <MyAppBar /> */}

      {/* <LandingPage /> */}
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          style={{ background: "#fff", zIndex: 10 }}
        >
          <CircularProgress style={{ color: 'gold' }} /> {/* Gold spinner */}
        </Box>
      ) : (
        <>
          <BlogMainPage bannerDates={bannerDates} handleReadMoreOpenModal={handleReadMoreOpenModal}/>
          <LatestNewsSection latestDates={latestDates} handleReadMoreOpenModal={handleReadMoreOpenModal} setReadMoreModalContent={setReadMoreModalContent}/>
          <NextStepForwardGold nextStepDates={nextStepDates} handleReadMoreOpenModal={handleReadMoreOpenModal}/>
          <RecentPosts recentPostDates={recentPostDates} handleReadMoreOpenModal={handleReadMoreOpenModal}/>

        </>
      )}
      {/* <Footer /> */}

      <ReadMoreModal open={isReadMoreModalOpen} onClose={handleReadMoreCloseModal} readMoreModalContent={readMoreModalContent}/>
      

    </div>
  )
}


export default BlogsHome;



