
// import { GetAllOrdersByCustomerAction } from "../Slices/Customer/GetAllOrdersByCustomer";
import { incrementQuantity } from "../Slices/AddToCard/cartSlice";
import { clearCartAction } from "../Slices/AddToCard/clearCart";
import { decrementQuantityAction } from "../Slices/AddToCard/decrementQuantity";
import { getCartItemCountAction } from "../Slices/AddToCard/getCartItemCount";
import { getCartItemsAction } from "../Slices/AddToCard/getCartItems";
import { getPlanByIdAction } from "../Slices/AddToCard/getPlanById";
import { incrementQuantityAction } from "../Slices/AddToCard/incrementQuantity";
import { addMultiPurchaseAction } from "../Slices/Customer/addMultiPurchase";
import { checkCustomerExistForEmailRegisterAction } from "../Slices/Customer/checkCustomerExistForEmailRegister";
import { checkCustomerExistPhoneForRegisterAction } from "../Slices/Customer/checkCustomerExistPhoneForRegister";
import { customerLoginAction } from "../Slices/Customer/customerLogin";
import { customerRegisterAction } from "../Slices/Customer/customerRegister";
import { getAllCitiesByStateAction } from "../Slices/Customer/getAllCitiesByState";
import { getAllCountryNamesAction } from "../Slices/Customer/getAllCountryNames";
import { getAllStatesByCountryAction } from "../Slices/Customer/getAllStatesByCountry";
import { getCustomerByIdAction } from "../Slices/Customer/getCustomerById";
import { getCustomerGoldBalanceDashboardAction } from "../Slices/Customer/getCustomerGoldBalanceDashboard";
import { getCustomerInfoForReapprovalAction } from "../Slices/Customer/getCustomerInfoForReapproval";
import { getCustomerRewardsListAction } from "../Slices/Customer/getCustomerRewardsList";
import { getKYCByCountryAction } from "../Slices/Customer/getKYCByCountry";
import { getPurchasesByCustomerIdAction } from "../Slices/Customer/getPurchasesByCustomerId";
import { getReferralRewardDashboardAction } from "../Slices/Customer/getReferralRewardDashboard";
import { ImageUploadPostAction } from "../Slices/Customer/ImageUploadPost";
import { requestReapprovalAction } from "../Slices/Customer/requestReapproval";
import { uploadPdfAndImageAction } from "../Slices/Customer/uploadPdfAndImage";
import { getCustomerNotificationsAction } from "../Slices/Notifications/getCustomerNotifications";
import { markNotificationAsReadForCustomerAction } from "../Slices/Notifications/markNotificationAsReadForCustomer";
import { PurchaseSchemesAction } from "../Slices/Schemes/PurchaseSchemes";
import { EmailSendOtpAction } from "../Slices/VerificationOtp/EmailSendOtp";
import { EmailVerifyOtpAction } from "../Slices/VerificationOtp/EmailVerifyOtp";
import { resetPasswordForCustomerAction } from "../Slices/VerificationOtp/resetPasswordForCustomer";
import { getAllAboutUsSectionsAction } from "../Slices/website/AboutUs/getAllAboutUsSections";
import { getAllBlogSectionsAction } from "../Slices/website/blog/getAllBlogSections";
import { getAllContactUsSectionsAction } from "../Slices/website/contactUs/getAllContactUsSections";
import { postContactUsFormAction } from "../Slices/website/contactUs/postContactUsForm";
import { getAllHomeSectionsAction } from "../Slices/website/home/getAllHomeSections";
import { getAllReferralsSectionsAction } from "../Slices/website/referrals/getAllReferralsSections";
import { getAllstakeNowSectionsAction } from "../Slices/website/stakeNow/getAllstakeNowSections";


const actions = {

  // ...RptLoadNoDropDownAction,
  // ...RptStatusDropDownAction,

  // ...CategoryTableGetAllAction,
  // ...GetAllOrdersByCustomerAction,
  ...getAllHomeSectionsAction,
  ...getAllstakeNowSectionsAction,
  ...getAllReferralsSectionsAction,
  ...getAllBlogSectionsAction,
  ...getAllContactUsSectionsAction,
  ...getAllAboutUsSectionsAction,
  ...postContactUsFormAction,
  ...customerRegisterAction,
  ...customerLoginAction,
  ...PurchaseSchemesAction,
  ...ImageUploadPostAction,
  ...getKYCByCountryAction,
  ...getCustomerGoldBalanceDashboardAction,
  ...getPurchasesByCustomerIdAction,
  ...getCustomerRewardsListAction,
  ...getReferralRewardDashboardAction,
  ...getCartItemCountAction,
  ...EmailSendOtpAction,
  ...EmailVerifyOtpAction,
  ...addMultiPurchaseAction,
  ...resetPasswordForCustomerAction,
  ...getPlanByIdAction,
  ...getCartItemsAction,
  ...clearCartAction,
  ...incrementQuantityAction,
  ...decrementQuantityAction,
  ...uploadPdfAndImageAction,
  ...checkCustomerExistForEmailRegisterAction,
  ...checkCustomerExistPhoneForRegisterAction,
  ...getAllCountryNamesAction,
  ...getAllStatesByCountryAction,
  ...getAllCitiesByStateAction,
  ...getCustomerByIdAction,
  ...getCustomerInfoForReapprovalAction,
  ...requestReapprovalAction,
  ...getCustomerNotificationsAction,
  ...markNotificationAsReadForCustomerAction,




};

export default actions;
