// export const adminUrl = "http://122.165.52.124:4000/api/v1/"; 
// export const adminUrl = "http://localhost:5000/api/v1/";

export const adminUrl = "https://goldstakebackend.cygnusa.in/api/v1/"; 
// export const adminUrl = "https://api.gorex.in/api/v1/"; 
// "homepage": "https://energy-dev.gorex.in/",



export const loginUrl = "";

// http://localhost:5000/api/v1/incrementQuantityN
// method : patch

// { 
//     "customer_id": 1,
//     "plan_id": 2
//   }


//   http://localhost:5000/api/v1/decrementQuantity

//   method : patch

//   {
//     "customer_id": 1,
//     "plan_id": 2
//   }

//   http://localhost:5000/api/v1/getCartItems/customer_id

// method :  get 