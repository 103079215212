



/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { defaultReject, defaultState } from "../../../constants";
import { fetchData } from "../../../helpers";

// GETCUSTOMERNOTIFICATIONS
// GETCUSTOMERNOTIFICATIONS


const GETCUSTOMERNOTIFICATIONS= createAsyncThunk(
  "getCustomerNotifications/getCustomerNotifications",
  // eslint-disable-next-line default-param-last
  async (
    // eslint-disable-next-line default-param-last
    payload = {},
    { rejectWithValue }
  ) => {
    try {
      const data = await fetchData(
        payload?.data,
        payload?.method,
        payload?.apiName
      );
      return {
        ...defaultState.List,
        message: data?.data.Message,
        data: data?.data?.data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject.List,
        message: error.message,
      });
    }
  }
);

// PublishPostSlice
const getCustomerNotificationsSlice = createSlice({
  name: "getCustomerNotificationsSlice",
  initialState: {
    getCustomerNotifications: {
      ...defaultState.List,
      loading: false, 
      error: false, 
    },
  },
  reducers: {
    setInitialStateOfgetCustomerNotifications: (state) => {
        state.getCustomerNotifications.loading = false
        state.getCustomerNotifications.error = false
        state.getCustomerNotifications.data = []
        state.getCustomerNotifications.message = ''
      }
  },
  extraReducers: (builder) => {
    builder.addCase(GETCUSTOMERNOTIFICATIONS.fulfilled, (state, action) => {
      state.getCustomerNotifications = {
        ...state.getCustomerNotifications,
        loading: false,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(GETCUSTOMERNOTIFICATIONS.pending, (state, action) => {
      state.getCustomerNotifications = {
        ...state.getCustomerNotifications,
        loading: true,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(GETCUSTOMERNOTIFICATIONS.rejected, (state, action) => {
      state.getCustomerNotifications = {
        ...state.getCustomerNotifications,
        loading: false,
        error: true,
        ...action.payload,
      };
    });
  },
});

const getCustomerNotificationsAction = {
    GETCUSTOMERNOTIFICATIONS,
};

export { getCustomerNotificationsAction };
export default getCustomerNotificationsSlice.reducer;
export const {setInitialStateOfgetCustomerNotifications} = getCustomerNotificationsSlice.actions
