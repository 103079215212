import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import "./BannerinputGetModal.css"; // Import external CSS
import { MdCancel } from "react-icons/md";
import registration_complete_gif from "../../../../../Assets/registration_successful_gif.gif";
import { useNavigate } from "react-router-dom";
// import registration_complete_gif from "../../../../../Assets/registration_successful_gif.gif"

const PaymentSuccessfullModal = ({
  open,
  onClose,
  setSubmittedValues,
  submittedValues,
  modalHeadingName,
  handleNextModal,
}) => {
  // const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = React.useState("");
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
    //   onClose={onClose}
      fullWidth
      maxWidth="md"
      className="custom-dialog"
      sx={{
        // maxWidth: "700px",
        // margin: "auto",
        "& .MuiPaper-root": {
          borderRadius: "14px", // Target the Paper component
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Box className="dialog-heading">
          <IconButton
            aria-label="Close"
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "text.secondary",
            }}
          >
            <MdCancel />
          </IconButton>
          <DialogTitle className="custom-dialog-title">
            <Box>
              <Typography className="custom-title-text" color="white">
                {/* {modalHeadingName} */}
                Payment Successful
              </Typography>
            </Box>
          </DialogTitle>
        </Box>

        <DialogContent dividers>
          <Grid container textAlign="center" sx={{ padding: { xs: 0, md: 2 } }}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h1"
                sx={{ fontSize: { xs: "20px", md: "30px" } }}
              >
                Payment Successful
              </Typography>
              <Typography
                variant="body1"
                sx={{ maxWidth: "600px", margin: "auto", mt: 1, color: "gray" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it
              </Typography>
              <Box component="img" src={registration_complete_gif} />

              <Typography variant="body2">
                THe purchased schemes will be updated to your account within 48
                hours
              </Typography>

              {/* <Typography variant="subtitle2" color="gray" mt={2}>
                Payment ID: 001232328383490
              </Typography> */}
              <Button
                onClick={() => navigate("/customer-dashboard")}
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "white",
                  fontSize: "14px",
                  boxShadow: "none",
                  padding: { xs: "0px 10px", md: "0px 20px" },
                  minHeight: "40px",
                  marginTop:"20px",
                  // borderRadius: "23px",
                  // background: "#eba30a 0% 0% no-repeat padding-box",
                  background: "#C38F51 0% 0% no-repeat padding-box",

                  // "&:hover": {
                  //   backgroundColor: "#3B4056",
                  //   boxShadow: "none",
                  // },
                }}
                //   disabled={!values.privacyChecked} // Disable button if checkbox is not checked
              >
                Go to dashboard
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default PaymentSuccessfullModal;
