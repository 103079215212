
import React from "react";
import "./CustomInputLogin.css";
import "../ComponentsCss/componet.css";
import { Field, ErrorMessage } from "formik";
import { InputAdornment } from "@mui/material";
import { Email } from "@mui/icons-material";

const CustomInputLogin = ({ label, name, custPlaceholder, image, inputType, ...rest }) => {
  const validateInput = (value) => {
    let error;
    if (!value) {
      error = `Field is Required`;
    } else if (inputType === "number" && isNaN(value)) {
      error = "Field is Required";
    }
    return error;
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <div>
          <label
            htmlFor={name}
            className="input-heading12"
            style={{ fontSize: "16px" }}
          >
            {label}
          </label>
        </div>
        <div style={{ position: "relative", width: "100%" }}>
          {/* Email Icon as Start Adornment */}
          {image && (
            < InputAdornment position="start" style={{ position: "absolute", top: "50%", left: "8px", transform: "translateY(-50%)" }}>
              <img src={image} style={{ color: "black", height: "17px" }} />
            </InputAdornment>
          )}
          {/* Input Field */}
          <Field
            id={name}
            name={name}
            as={inputType === "textarea" ? "textarea" : "input"}
            placeholder={custPlaceholder || "Enter Input"}
            {...rest}
            style={{

              borderRadius:"3px",
              width: "100%",
              color: "#7A7A7A",

              // paddingLeft: "40px", // Add padding to accommodate the icon
              boxSizing: "border-box",
              height: inputType === "textarea" ? "100px" : "35px",
              paddingLeft: inputType === "textarea" ? "10px" : "40px" // Add padding to accommodate the icon

            }}
            // sx={inputType === "textarea" ? { height: "100px" || "100px" } : {}}
            className="custominput-field12"
            validate={validateInput}
            
          />
        </div>
        <ErrorMessage
          name={name}
          component="div"
          // fontSize="10px"
          // className="inputs-error-msg"
          style={{ color: "black",fontSize:"30px" }}
          // sx={{fontSize:"30px"}}
        />
      </div>
    </div >
  );
};

export default CustomInputLogin;
