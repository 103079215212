
import React from "react";
import { Box, Button, Typography, Container, Stack, Grid } from "@mui/material";
import financialImage from "../../../Assets/financialImage.svg"; // Ensure this is the correct path
import financialImageBackground from "../../../Assets/financialImageBackgroud.svg"; // Ensure this is the correct path
import { adminUrl } from "../../../constants";

const AreYouFinancial = ({ financialConsultantDatas }) => {
    return (
        <Box
            sx={{
                position: "relative",
                background: "#F7F7F7",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
                padding: "50px 0px",
            }}
        >
            <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                <Grid
                    container
                    xs={12}
                >
                    <Grid
                        item
                        md={4}
                        sx={{
                            display: "flex", // Flexbox to apply justifyContent and alignItems
                            justifyContent: "center", // Center horizontally
                            alignItems: "center", // Center vertically
                            backgroundImage: `url(${financialImageBackground})`, // Set background image
                            backgroundSize: "contain", // Make the image cover the entire container
                            backgroundRepeat: "no-repeat", // Prevent the image from repeating
                            backgroundPosition: "center", // Center the image
                            height: "300px", // Ensure it covers the full height of the container
                            width: "100%", // Ensure it covers the full width of the container
                            position: "relative", // Needed for z-index control
                            zIndex: 0, // Keep it behind other elements
                            display: {
                                xs: "none", // Hide on extra small screens
                                sm: "flex", // Show on small screens and above
                            },
                        }}
                    >
                        <img src={`${adminUrl}${financialConsultantDatas?.[0]?.additional_data?.[0]?.banner}`} alt="Refer" style={{ height: "250px", zIndex: 1, position: "relative" }} />
                    </Grid>

                    {/* Left Section */}
                    <Grid
                        item
                        md={8}
                        sx={{ color: "#fff" ,display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <Box>
                            <Typography
                                variant="h1"
                                textAlign="start"
                                sx={{
                                    // fontSize: "40px",
                                    fontSize: {xs:"30px",md:"40px"},
                                    
                                    // maxWidth: "600px",
                                    color: "#3B4056",
                                    mb: 1,

                                }}
                            >
                                {financialConsultantDatas?.[0]?.heading || "Default Sub-heading"}
                                {/* Are you a Financial Consultant ? */}

                            </Typography>

                            <Typography
                                variant="h3"
                                textAlign="start"
                                sx={{
                                    mt: 2,
                                    fontSize: {xs:"16px",md:"18px"},

                                    fontWeight: "600",
                                    // maxWidth: "600px",
                                    color: "#C38F51",
                                    mb: 1,

                                }}
                            >
                                {financialConsultantDatas?.[0]?.heading || "Default Sub-heading"}
                                {/* Join us and become a financial Consultant to generate a hussle free income */}

                            </Typography>



                            <Typography
                                variant="body1"
                                textAlign="start"
                                sx={{
                                    mt: 2,

                                    mb: 3,
                                    lineHeight: "1.6",
                                    color: "#7A7A7A",
                                    maxWidth: "600px",
                                    fontSize: "14px",
                                    letterSpacing: "0px",
                                    opacity: 1,
                                }}
                            >
                                {financialConsultantDatas?.[0]?.content || "Default Sub-heading"}
                                {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown */}

                            </Typography>

                            <Stack spacing={2} direction="row" sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        textTransform: "none",
                                        color: "white",
                                        boxShadow: "none",

                                        padding: "0px 60px",
                                        height: "40px",
                                        borderRadius: "23px",
                                        background: "#3B4056 0% 0% no-repeat padding-box",
                                        "&:hover": {
                                            backgroundColor: "#C38F51",
                                        },
                                    }}
                                >
                                    {financialConsultantDatas?.[0]?.additional_data?.[0]?.primary_button_text || "Join us"}

                                </Button>
                            </Stack>
                        </Box>
                    </Grid>
                    {/* Right Section: Image */}

                </Grid>
            </Container>
        </Box>
    );
};

export default AreYouFinancial;
