

import { Drawer, Box, IconButton, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText } from "@mui/material";
import { Close } from "@mui/icons-material";
import { MdAddTask } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { AiFillGolden } from "react-icons/ai";
import { MdOutlineLockReset } from "react-icons/md";
import { GiCash } from "react-icons/gi";
// import { TiWarning } from "react-icons/ti";
import { IoMdWarning } from "react-icons/io";
import { MdNewLabel } from "react-icons/md";
import { GiGoldBar } from "react-icons/gi";
import { PiHandWithdrawFill } from "react-icons/pi";
import actions from "../../ReduxStore/actions";
import { useDispatch, useSelector } from "react-redux";



const Notification = ({ open, onClose, notifications }) => {
  const navigate = useNavigate();

  const targetRef = useRef(null); // Create a reference for the target element

  const handleScrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  // const handleNavigation = (notification) => {
  //   if (notification?.type === "plan") {
  //     navigate("/stake-now", { state: { scrollToSection: true } });
  //     onClose();
  //   }
  // }

  const { markNotificationAsReadForCustomer } = useSelector((state) => state?.markNotificationAsReadForCustomer);


  const dispatch = useDispatch()

  const handleNavigation = async (notification) => {

    const data1 = {
      data: {},
      method: "put",
      apiName: `markNotificationAsReadForCustomer/${notification?.id}`,
    };
    await dispatch(actions.MARKNOTIFICATIONASREADFORCUSTOMER(data1));




    if (notification?.type === "plan") {
      navigate("/stake-now"); // Navigate to the page
      onClose(); // Close the notification drawer

      // Delay scrolling to ensure navigation is completed
      setTimeout(() => {
        const targetElement = document.getElementById("name");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 500); // Adjust timeout as needed
    } else if (notification?.type === "purchases") {
      navigate("/customer-dashboard"); // Navigate to the page
      onClose(); // Close the notification drawer
    }
    // else if (notification?.type === "customers") {
    //   navigate("/stake-now"); // Navigate to the page
    //   onClose(); // Close the notification drawer
    // } 
    else if (notification?.type === "referral_rewards") {
      navigate("/customer-dashboard"); // Navigate to the page
      onClose(); // Close the notification drawer
    } else if (notification?.type === "reaps") {
      navigate("/customer-dashboard"); // Navigate to the page
      onClose(); // Close the notification drawer
    } else if (notification?.type === "gold_withdrawals") {
      navigate("/customer-dashboard"); // Navigate to the page
      onClose(); // Close the notification drawer
    } else if (notification?.type === "customers" && notification?.title==="Password has been reset") {
      navigate("/profile"); // Navigate to the page
      onClose(); // Close the notification drawer
    }
  
  };


  // const handleNavigation = (notification) => {
  //   if (notification?.type === "plan") {
  //     navigate("/stake-now"); // Update this path as per your route structure

  //     onClose()
  //      setTimeout(() => {
  //     handleScrollToTarget();
  //   }, 500); 
  //   }
  // };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: 300, p: 2 }}>
        <IconButton onClick={onClose} sx={{ float: "right" }}>
          <Close />
        </IconButton>
        <Typography variant="h6" sx={{ mb: 2 }}>Notifications</Typography>
        <List>
          {notifications?.map((notification) => (
            <ListItem
              key={notification?.id}
              alignItems="center"
              sx={{
                p: "10px 5px",
                background: "#f1f1f1",
                marginBottom: 1,
                cursor: notification?.type === "plan" ? "pointer" : "default",
              }}
              onClick={() => handleNavigation(notification)}
            >
              <ListItemAvatar>
                {notification?.type === "plan" ? (
                  <MdAddTask
                    size={24}
                    style={{
                      marginLeft: "10px",
                      borderRadius: "29px",
                      color: "white",
                      background: "rgb(207 151 85)",
                      padding: "5px",
                    }}
                  />
                ) : notification?.type === "purchases" ? (
                  <AiFillGolden
                    size={24}
                    style={{
                      marginLeft: "10px",
                      borderRadius: "29px",
                      color: "white",
                      background: "green",
                      padding: "5px",
                    }}
                  />
                ) :
                  notification?.type === "customers" ? (
                    <MdOutlineLockReset
                      size={24}
                      style={{
                        marginLeft: "10px",
                        borderRadius: "29px",
                        color: "white",
                        background: "#3b3b39",
                        padding: "5px",
                      }}
                    />
                  ) :
                    notification?.type === "referral_rewards" ? (
                      <GiCash
                        size={24}
                        style={{
                          marginLeft: "10px",
                          borderRadius: "29px",
                          color: "white",
                          background: "#d99a28",
                          padding: "5px",
                        }}
                      />
                    ) :
                      notification?.type === "tiers" ? (
                        notification?.title === "New Referral Level Added" ? (
                          <MdNewLabel
                            size={24}
                            style={{
                              marginLeft: "10px",
                              borderRadius: "29px",
                              color: "white",
                              background: "green",
                              padding: "5px",
                            }}
                          />) : (
                          <IoMdWarning
                            size={24}
                            style={{
                              marginLeft: "10px",
                              borderRadius: "29px",
                              color: "white",
                              background: "#e11717",
                              padding: "5px",
                            }}
                          />)
                      ) : notification?.type === "reaps" ? (
                        <GiGoldBar
                          size={24}
                          style={{
                            marginLeft: "10px",
                            borderRadius: "29px",
                            color: "#ffe200",
                            background: "black",
                            padding: "5px",
                          }}
                        />
                      ) :
                        notification?.type === "gold_withdrawals" ? (
                          <PiHandWithdrawFill
                            size={24}
                            style={{
                              marginLeft: "10px",
                              borderRadius: "29px",
                              color: "white",
                              background: "#3b4056",
                              padding: "5px",
                            }}
                          />
                        ) : (
                          <Avatar src={notification?.image} alt={notification?.title} />
                        )}
              </ListItemAvatar>


              <ListItemText
                primary={
                  <Typography variant="subtitle2" fontWeight="bold">
                    {notification?.title}
                  </Typography>
                }
                secondary={
                  <Typography variant="body1" color="textSecondary">
                    {notification?.content}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer >
  );
};

export default Notification;
