

import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Typography, TextField, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../CustomInput/CustomInput';

const generateCaptchaText = () => {
    const chars = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789"; // Avoids O, 0, I, 1
    return Array.from({ length: 6 }, () => chars[Math.floor(Math.random() * chars.length)]).join("");
};

const drawCaptcha = (text, canvasRef) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.width = 200;
    canvas.height = 50;

    ctx.fillStyle = "#f2f2f2";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    for (let i = 0; i < 5; i++) {
        ctx.strokeStyle = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.7)`;
        ctx.lineWidth = Math.random() * 2;
        ctx.beginPath();
        ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.stroke();
    }

    ctx.font = "bold 30px Arial";
    ctx.textBaseline = "middle";
    for (let i = 0; i < text.length; i++) {
        const x = 30 + i * 25;
        const y = 30 + Math.random() * 10 - 5;
        const angle = (Math.random() * 20 - 10) * (Math.PI / 180);

        ctx.save();
        ctx.translate(x, y);
        ctx.rotate(angle);
        ctx.fillStyle = `rgb(${Math.random() * 150}, ${Math.random() * 150}, ${Math.random() * 150})`;
        ctx.fillText(text[i], 0, 0);
        ctx.restore();
    }
};

const SignInForm = ({ handleSubmit, toggleForgotPassword, handleGoRegistrationPage }) => {
    const [captchaText, setCaptchaText] = useState(generateCaptchaText());
    const canvasRef = useRef(null);

    useEffect(() => {
        drawCaptcha(captchaText, canvasRef);
    }, [captchaText]);

    const refreshCaptcha = () => {
        const newCaptcha = generateCaptchaText();
        setCaptchaText(newCaptcha);
        drawCaptcha(newCaptcha, canvasRef);
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
        captcha: Yup.string().required('CAPTCHA is required')
    });

    return (
        <Formik
            initialValues={{ email: '', password: '', captcha: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setFieldError }) => {
                if (values.captcha.toUpperCase() !== captchaText) {
                    setFieldError("captcha", "CAPTCHA is incorrect. Try again.");
                    refreshCaptcha();
                } else {
                    handleSubmit(values);
                }
            }}
        >
            {({ errors, touched, handleChange }) => (
                <Form style={{ width: '100%' }}>
                    <Box mb={1}>
                        <CustomInput name="email" label="Email" custPlaceholder="Enter Email" inputType="text" />
                    </Box>

                    <Box mb={1}>
                        <CustomInput name="password" label="Password" custPlaceholder="Enter Password" inputType="password" />
                    </Box>

                    {/* CAPTCHA Section */}
                    <Box display="flex" flexDirection="column" alignItems="center" mb={1}>
                        <Box display="flex" flexDirection="row" alignItems="center" mb={1} mt={1}>

                            <canvas ref={canvasRef} style={{ width: "100%", maxWidth: "400px", borderRadius: 8 }} ></canvas>
                            <IconButton onClick={refreshCaptcha} sx={{ mt: 1 }}>
                                <RefreshIcon />
                            </IconButton>
                        </Box>
                        <CustomInput
                            name="captcha"
                            label="Captcha"
                            custPlaceholder="Enter CAPTCHA"
                            // inputType="password"
                            onChange={handleChange}

                        />

                        {/* <TextField
                            name="captcha"
                            label="Enter CAPTCHA"
                            fullWidth
                            margin="normal"
                            error={touched.captcha && Boolean(errors.captcha)}
                            helperText={touched.captcha && errors.captcha}
                            onChange={handleChange} */}
                        {/* /> */}
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="end"
                        alignItems="end"
                        width="100%"
                        mb={3}
                        color="#C38F51"
                    >
                        <Typography variant='body1' fontSize="16px">
                            <Typography
                                variant='subtitle1'
                                onClick={toggleForgotPassword}
                                underline="none"
                                sx={{ cursor: "pointer" }}
                                color='#C38F51'
                            >
                                Forgot password?
                            </Typography>
                        </Typography>
                    </Box>

                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{
                            borderRadius: 23,
                            py: "7px",
                            color: "white",
                            backgroundColor: "#C38F51"
                        }}
                    >
                        Sign In
                    </Button>

                    <Typography variant="body1" mt={2} sx={{ fontSize: "16px", textAlign: 'center' }}>
                        Don't have an account?&nbsp;
                        <Box sx={{ color: "#C38F51", cursor: "pointer" }} onClick={handleGoRegistrationPage}>
                            Register here
                        </Box>
                    </Typography>
                </Form>
            )
            }
        </Formik >
    );
};

export default SignInForm;
