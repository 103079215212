
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import login_bgImage from "../../Assets/login_bgImage.png";
import logo_login_image from "../../Assets/Login Logo.png";
import CustomInputLogin from "../CustomInput/CustomInput";
import CustoDropDownRegister from "../CustomDropDown/CustoDropDownRegister";
import ProfileUpload from "../ProfileUpload/ProfileUpload";
import { RiDeleteBin2Line } from "react-icons/ri";
import { AiOutlineEye, AiOutlineProfile } from "react-icons/ai";
import actions from "../../ReduxStore/actions/index";
import { useDispatch, useSelector } from "react-redux";

// import React from 'react';
import {
    // Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    LinearProgress,
    IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomEmailVerifyInput from "../CustomEmailVerifyInput/CustomEmailVerifyInput";
import CustomMobileNumber from "../CustomMobileNumber/CustomMobileNumber";
import { FiUpload } from "react-icons/fi";
import { HiDocumentArrowUp } from "react-icons/hi2";
import CustomDropdownDocumentType from "../CustomDropDown/CustomDropdownDocumentType";
import TermsandConditions from "../TermsandConditions/TermsandConditions";
import { useNavigate, useParams } from "react-router-dom";
import CustImageUploadInput from "../ProfileUpload/ProfileUpload";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomPhoneVerifyInput from "../CustomMobileNumber/CustomMobileNumber";
import CloseIcon from "@mui/icons-material/Close";
import WarningModal from "./WarningModal";
import { adminUrl } from "../../constants";
import { MdCancel } from "react-icons/md";

// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';

// Styled Stepper with dashed lines
const DashedStepper = styled(Stepper)(({ theme }) => ({
    "& .MuiStepConnector-line": {
        borderStyle: "dashed",
    },
    "& .MuiStepConnector-root": {
        minHeight: 1,
    },
}));

const validationSchemaStep0 = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    role: Yup.string().required("Type is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    // isEmailVerified: Yup.boolean().oneOf(
    //   [true],
    //   "Please verify your OTP before submitting"
    // ),
    // isVerified: Yup.boolean().oneOf(
    //   [true],
    //   "Please verify your OTP before submitting"
    // ),
    // phone: Yup.string()
    //     .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    //     .required("Phone number is required"),
    // password: Yup.string()
    //     .min(8, "Password must be at least 8 characters")
    //     .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    //     .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    //     .matches(/\d/, "Password must contain at least one number")
    //     .matches(
    //         /[!@#$%^&*(),.?":{}|<>]/,
    //         "Password must contain at least one special character"
    //     )
    //     .required("Password is required"),

    // confirmPassword: Yup.string()
    //     .oneOf([Yup.ref("password"), null], "Passwords must match")
    //     .required("Confirm Password is required"),
});

const validationSchemaStep1 = Yup.object({
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    zip_code: Yup.string().required("Zipcode is required"),
    address_1: Yup.string().required("Address Line 1 is required"),
    address_2: Yup.string().required("Address Line 2 is required"),
});

// const validationSchemaStep2 = Yup.object({
//     documentType: Yup.string().required('Document Type is required'),
//     imageupload: Yup.string().required('Image upload is required'), // Adjust as needed for file upload
// });

const ReApprovalRegisterComponent = () => {
    const [emailInput, setEmailInput] = useState(""); // Track email input

    const [phoneInput, setPhoneInput] = useState(""); // Track phone number input

    const [checked, setChecked] = useState(false);

    const handleCheckChange = (isChecked) => {
        setChecked(isChecked);
    };

    // const handleGoRegisterComplete = () => {
    //     console.log('Form Submitted');
    // };

    // const [selectedDocumentType, setSelectedDocumentType] = React.useState('');
    // const [uploadedFiles, setUploadedFiles] = React.useState([]);
    // const [documentTypeDropData, setDocumentTypeDropData] = React.useState([]);

    // const dispatch = useDispatch();
    // const { getKYCByCountry } = useSelector((state) => state?.getKYCByCountry);
    // const documentdropData =setDocumentTypeDropData(getKYCByCountry?.data?.kycs)
    // console.log(documentdropData, "getKYCByCountrygetKYCByCountry")
    // const options = documentdropData.map(item => ({
    //     label: item,
    //     value: item
    // }));

    // useEffect(() => {
    //     const data1 = {
    //         data: {},
    //         method: "get",
    //         apiName: "getKYCByCountry/Dubai"
    //     };

    //     dispatch(actions.GETKYCBYCOUNTRY(data1));

    // }, [dispatch])

    const [isVerified, setIsVerified] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);

    const [selectedDocumentType, setSelectedDocumentType] = React.useState("");
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [documentTypeDropData, setDocumentTypeDropData] = React.useState([]);
    const dispatch = useDispatch();

    console.log(uploadedFiles, "uploaded fileee");

    const [formValues, setFormValues] = useState({
        first_name: "",
        last_name: "",
        role: "",
        referred_by: "",
        email: "",
        phone: "",
        // isVerified: false,
        // isEmailVerified: false,
        // password: "",
        // confirmPassword: "",
        countrycode: "",

        country: "",
        state: "",
        city: "",
        zip_code: "",
        address_1: "",
        address_2: "",
        profileImage: "",
    });


    console.log(formValues, "formValuesformValuesformValues")
    const { getKYCByCountry } = useSelector((state) => state?.getKYCByCountry);

    // Ensure documentTypeDropData is set once getKYCByCountry is updated
    useEffect(() => {
        if (getKYCByCountry?.data?.kycs) {
            setDocumentTypeDropData(getKYCByCountry.data.kycs);
        }
    }, [getKYCByCountry]);

    const options = documentTypeDropData.map((item) => ({
        label: item,
        value: item,
    }));

    // useEffect(() => {
    //   const data1 = {
    //     data: {},
    //     method: "get",
    //     apiName: "getKYCByCountry/1",
    //   };

    //   dispatch(actions.GETKYCBYCOUNTRY(data1));
    // }, [dispatch]);

    const [reApprovalData, setReApprovalData] = useState([])

    useEffect(() => {
        const fetchApprovalData = async () => {
            const data = {
                data: {},
                method: "get",
                apiName: `getCustomerInfoForReapproval/${reapprovallink}`,
            };

            const responseData = await dispatch(actions.GETCUSTOMERINFOFORREAPPROVAL(data));

            if (responseData?.payload?.message === "Customer details fetched successfully.") {
                setReApprovalData(responseData?.payload?.data)
                setIsEmailVerified(true)
                setIsVerified(true)
                setEmailInput(responseData?.payload?.data?.email)
                setPhoneInput(responseData?.payload?.data?.phone)
            }
            else {
                navigate("/rejectstatus")

            }
            //     const formattedCountries =
            //         responseData?.payload?.data?.map((country) => ({
            //             label: country.country_name,
            //             value: country.country_name,
            //             id: country.country_id, // Store country ID
            //         })) || [];

            //     setCountryNames(formattedCountries);
            // }
        };

        fetchApprovalData();
    }, [dispatch]);


    // useEffect(() => {
    //     if (reApprovalData?.country) {
    //         const selectedCountry = countryNames.find(c => c.label === reApprovalData.country);
    //         if (selectedCountry) {
    //             setSelectedCountryId(selectedCountry.id);
    //             fetchStates(selectedCountry.id);  // Fetch states based on country
    //         }
    //     }
    // }, [reApprovalData, countryNames]);

    // useEffect(() => {
    //     if (reApprovalData?.state) {
    //         const selectedState = stateNames.find(s => s.label === reApprovalData.state);
    //         if (selectedState) {
    //             setSelectedStateId(selectedState.id);
    //             fetchCities(selectedState.id);  // Fetch cities based on state
    //         }
    //     }
    // }, [reApprovalData, stateNames]);

    // useEffect(() => {
    //     if (reApprovalData?.city) {
    //         setSelectedCity(reApprovalData.city);
    //     }
    // }, [reApprovalData]);


    const handleFileUpload = async (event) => {
        const file = event.target.files[0];

        console.log(file, "filefilefilefile");

        if (file) {
            const formData = new FormData();

            // Append the file to the FormData object
            formData.append("uploadFile", file);

            const data1 = {
                data: formData,
                method: "post",
                apiName: "uploadPdfAndImage",
            };

            const uploadPdfAndImageResponse = await dispatch(
                actions.UPLOADPDFANDIMAGE(data1)
            );

            console.log(
                uploadPdfAndImageResponse.payload.data.fileUrl,
                "formData12121"
            );

            setUploadedFiles((prevFiles) => [
                ...prevFiles,
                {
                    fileUrl: uploadPdfAndImageResponse.payload.data.fileUrl,
                    type: selectedDocumentType,
                    progress: 100,
                },
            ]);

            console.log(uploadedFiles, "uploadedFilesuploadedFilesuploadedFiles");
        }
    };

    const handleFileDelete = (index) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const [customerRegisterData, setCustomerRegisterData] = useState(null);

    const [activeStep, setActiveStep] = useState(0);

    const steps = ["Basic Information", "Address", "Documents"];
    const [viewImgApi, setViewImgApi] = useState(null);

    // const getInitialValues = () => {
    //     if (activeStep === 0) {
    //         return {
    //             first_name: reApprovalData?.first_name || formValues.first_name,
    //             last_name: reApprovalData?.last_name || formValues.last_name,
    //             role: reApprovalData?.role || formValues.role,
    //             referred_by: reApprovalData?.referred_by || formValues.referred_by,
    //             email: reApprovalData?.email || formValues.email,
    //             countrycode: reApprovalData?.countrycode || formValues.countrycode,
    //             phone: reApprovalData?.phone || formValues.phone,
    //             // isVerified: formValues.isVerified,
    //             // isEmailVerified: formValues.isEmailVerified,
    //             // password:reApprovalData?.first_name || formValues.password,
    //             // confirmPassword: reApprovalData?.first_name ||formValues.confirmPassword,
    //             profileImage: reApprovalData?.profileImage || formValues.profileImage,
    //         };
    //     } else if (activeStep === 1) {
    //         return {
    //             country: reApprovalData?.country || formValues.country,
    //             state: reApprovalData?.state || formValues.state,
    //             city: reApprovalData?.city || formValues.city,
    //             zip_code: reApprovalData?.zip_code || formValues.zip_code,
    //             address_1: reApprovalData?.address_1 || formValues.address_1,
    //             address_2: reApprovalData?.address_2 || formValues.address_2,
    //         };
    //     }
    //     return formValues; // Default to full form values
    // };


    const getInitialValues = () => {
        return {
            ...formValues, // Preserve previously entered values
            // ...(activeStep === 0
            //     ? {
            first_name: reApprovalData?.first_name || formValues.first_name,
            last_name: reApprovalData?.last_name || formValues.last_name,
            role: reApprovalData?.role || formValues.role,
            referred_by: reApprovalData?.referred_by || formValues.referred_by,
            email: reApprovalData?.email || formValues.email,
            // countrycode: reApprovalData?.countrycode || formValues.countrycode,
            phone: reApprovalData?.phone || formValues.phone,
            profileImage: reApprovalData?.profileImage || formValues.profileImage,
            // }
            // : activeStep === 1
            //     ? {
            country: reApprovalData?.country || formValues.country,
            state: reApprovalData?.state || formValues.state,
            city: reApprovalData?.city || formValues.city,
            zip_code: reApprovalData?.zip_code || formValues.zip_code,
            address_1: reApprovalData?.address_1 || formValues.address_1,
            address_2: reApprovalData?.address_2 || formValues.address_2,
            // }
            // : {}),

        };
    };

    useEffect(() => {
        if (reApprovalData?.kycPdf?.length > 0) {
            setUploadedFiles(reApprovalData.kycPdf);
        }
        if (reApprovalData?.profileImage) {
            setViewImgApi(reApprovalData?.profileImage);
        }
        console.log(reApprovalData?.profileImage,"profileImageSHdjehdjejr")
    }, [reApprovalData?.kycPdf, setUploadedFiles,reApprovalData?.profileImage]);

    console.log(reApprovalData, "reApprovalDatareApprovalData")
    const handleNext = () => {
        if (!isVerified && !isEmailVerified) {
            // console.log("1111111111")
            toast.error("Please verify Otp!")
        } else if (!isEmailVerified) {
            // console.log("1111111111")
            toast.error("Please verify email Otp!")
        } else if (!isVerified) {
            // console.log("1111111111")
            toast.error("Please verify phone number Otp!")
        } else if (activeStep < steps.length - 1) {
            setIsVerified(true);
            setIsEmailVerified(true);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        // console.log(isEmailVerified,"1111111111")


    };
    const navigate = useNavigate();


    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };
    const validationSchema =
        activeStep === 0
            ? validationSchemaStep0
            : activeStep === 1
                ? validationSchemaStep1
                : null;
    //  validationSchemaStep2;

    const { customerRegister } = useSelector((state) => state?.customerRegister);
    const { ImageUploadPost } = useSelector((state) => state?.ImageUploadPost);

    console.log(customerRegister);


    const [isWarningModalOpen, setWarningModalOpen] = useState(false);
    const handleWarningOpenModal = () => {
        setWarningModalOpen(true);
    };

    const handleWarningCloseModal = () => {
        setWarningModalOpen(false);
    };


    // -----------------------------------------------------


    const { getAllCountryNames } = useSelector((state) => state?.getAllCountryNames);
    const { getAllCitiesByState } = useSelector((state) => state?.getAllCitiesByState);

    const { getAllStatesByCountry } = useSelector((state) => state?.getAllStatesByCountry);
    const { reapprovallink } = useParams();



    const [countryNames, setCountryNames] = useState([]);
    const [stateNames, setStateNames] = useState([]);
    const [cityNames, setCityNames] = useState([]);

    const [selectedCountryId, setSelectedCountryId] = useState(null);
    const [selectedStateId, setSelectedStateId] = useState(null);
    const [DocumentNames, setDocumentNames] = useState([]);




    useEffect(() => {
        if (countryNames.length > 0 && reApprovalData?.country) {
            const countryObj = countryNames.find(
                (country) => country.value === reApprovalData.country
            );

            if (countryObj) {
                setSelectedCountryId(countryObj.id);
                fetchStates(countryObj.id);
                fetchDocuments(countryObj.id);
            }
        }
    }, [countryNames, reApprovalData]);

    // useEffect(() => {
    //     setUploadedFiles((prevFiles) => [
    //         ...prevFiles,
    //         {
    //             fileUrl: uploadPdfAndImageResponse.payload.data.fileUrl,
    //             type: selectedDocumentType,
    //             progress: 100,
    //         },
    //     ]);
    // }, [reApprovalData]);

    const [isFirstRender, setIsFirstRender] = useState(true);




    console.log(selectedCountryId, "selectedCountryIdselectedCountryId")
    console.log(selectedStateId, "selectedStateIdselectedStateId")
    useEffect(() => {
        if (stateNames.length > 0 && reApprovalData?.state) {
            const stateObj = stateNames.find(
                (state) => state.value === reApprovalData.state
            );

            if (stateObj) {
                setSelectedStateId(stateObj.id);
                fetchCities(stateObj.id);
                // fetchDocuments(countryObj.id);
            }
        }
    }, [stateNames, reApprovalData]);

    // const { setFieldValue } = useFormikContext();

    // useEffect(() => {
    //     if (reApprovalData && Object.keys(reApprovalData).length > 0) {
    //         setFieldValue("country", reApprovalData.country || "");
    //         setFieldValue("state", reApprovalData.state || "");
    //         setFieldValue("city", reApprovalData.city || "");
    //     }
    // }, [reApprovalData, setFieldValue]);
    // Fetch countries on component mount
    useEffect(() => {
        const fetchCountries = async () => {
            const data = {
                data: {},
                method: "get",
                apiName: "getAllCountryNames",
            };

            const responseData = await dispatch(actions.GETALLCOUNTRYNAMES(data));

            if (responseData?.payload?.message === "Countries retrieved successfully.") {
                const formattedCountries =
                    responseData?.payload?.data?.map((country) => ({
                        label: country.country_name,
                        value: country.country_name,
                        id: country.country_id, // Store country ID
                    })) || [];

                setCountryNames(formattedCountries);
            }
        };

        fetchCountries();
    }, [dispatch]);


    const fetchDocuments = async (country_id) => {


        const data1 = {
            data: {},
            method: "get",
            apiName: `getAllKYCsByCountry/${country_id}`,
        };

        const responseData = await dispatch(actions.GETKYCBYCOUNTRY(data1));
        console.log(responseData?.payload, "jdhfdfdhfjdhfjdjf")

        if (responseData?.payload?.message === "KYCs retrieved successfully.") {
            const formattedDocumentNames =
                responseData?.payload?.data?.map((documents) => ({

                    label: documents.kyc_name,

                    value: documents.kyc_name,
                    id: documents.kyc_id, // Store country ID
                })) || [];

            setDocumentNames(formattedDocumentNames);
        }
    }

    // Handle country selection
    const handleCountryChange = (selectedValue, selectedId) => {
        console.log("Selected Country:", selectedValue);
        console.log("Selected Country ID:", selectedId);

        setSelectedCountryId(selectedId);
        setStateNames([]); // Reset states
        setCityNames([]); // Reset cities
        setDocumentNames([]); // Reset KYC documents on country change


        if (selectedId) {
            fetchStates(selectedId);
            fetchDocuments(selectedId)
        }
    };

    // Fetch states based on selected country
    const fetchStates = async (countryId) => {
        if (!countryId) return;

        const data = {
            data: { country_id: countryId },
            method: "get",
            apiName: `getAllStatesByCountry/${countryId}`,
        };

        const response = await dispatch(actions.GETALLSTATESBYCOUNTRY(data));

        if (response?.payload?.message === "States fetched successfully.") {
            const formattedStates =
                response?.payload?.data?.map((state) => ({
                    label: state.state_name,
                    value: state.state_name,
                    id: state.state_id, // Store state ID
                })) || [];

            setStateNames(formattedStates);
        }
    };

    // Handle state selection
    const handleStateChange = (selectedValue, selectedId) => {
        console.log("Selected State:", selectedValue);
        console.log("Selected State ID:", selectedId);

        setSelectedStateId(selectedId);
        setCityNames([]); // Reset cities

        if (selectedId) {
            fetchCities(selectedId);
        }
    };

    // Fetch cities based on selected state
    const fetchCities = async (stateId) => {
        const data = {
            data: { state_id: stateId },
            method: "get",
            apiName: `getAllCitiesByState/${stateId}`,
        };

        const response = await dispatch(actions.GETALLCITIESBYSTATE(data));

        if (response?.payload?.message === "Cities fetched successfully.") {
            const formattedCities =
                response?.payload?.data?.map((city) => ({
                    label: city.city_name,
                    value: city.city_name,
                })) || [];

            setCityNames(formattedCities);
        }
    };

    // ---------------------------------------------------------


    const regiterFormSubmitionFn = async (values, uploadedFiles) => {
        if (activeStep === steps.length - 1 && uploadedFiles.length !== 0) {
            const data1 = {
                data: {
                    ...values,
                    kycPdf: uploadedFiles,
                },
                method: "post",
                apiName: `requestReapproval/${reapprovallink}`,
            };

            const result = await dispatch(actions.REQUESTREAPPROVAL(data1));
            setCustomerRegisterData(result);
        } else {
            handleWarningOpenModal();
        }
    };

    useEffect(() => {
        if (
            customerRegisterData?.payload?.message ===
            "Reapproval request submitted successfully. Awaiting approval."
        ) {
            toast.success("Reapproval request submitted successfully. Awaiting approval.");
            // sessionStorage.setItem("sessioncustomer_id", customerRegisterData?.payload?.data.customer_id);
            // sessionStorage.setItem("sessionFirstName", customerRegisterData?.payload?.data.first_name);
            setIsVerified(false);
            setIsEmailVerified(false);
            setTimeout(() => {
                navigate("/login");
            }, 2500);
        } else {
            console.log(
                customerRegisterData?.payload?.message,
                "hiiiiiiiiiiiiiiiiiiiiiiiiiiii"
            );
            toast.error(customerRegisterData?.payload?.message);
        }
    }, [customerRegisterData]); // This effect will run when customerRegister changes

    // const handleGoRegisterComplete = () => {
    //     // if (customerRegister?.message === "Customer registered successfully") {
    //     //     toast.success("Registered successfully");
    //     //     sessionStorage.setItem(
    //     //         "sessioncustomer_id",
    //     //         customerRegister.payload?.data.customer_id
    //     //     );
    //     //     sessionStorage.setItem(
    //     //         "sessioProfileImage",
    //     //         customerRegister.payload?.data.first_name
    //     //     );
    //     //     setTimeout(() => {
    //     //         navigate("/registercomplete");
    //     //         // onClose();
    //     //     }, 2500);
    //     //     // navigate('/registercomplete')
    //     // }
    //     // else {
    //     //     console.log(customerRegister?.message, "hiiiiiiiiiiiiiiiiiiiiiiiiiiii")
    //     //     toast.error(customerRegister?.message);

    //     // }
    // }

    const [previewFile, setPreviewFile] = useState(null);
    console.log(previewFile, "previewFilepreviewFile");

    const handlePreview = (file) => {
        setPreviewFile(file);
    };

    const handleClosePreview = () => {
        setPreviewFile(null);
    };

    return (
        <Grid container sx={{ boxShadow: 3, height: "100vh" }}>
            {/* Left Section */}
            <Grid
                item
                md={5}
                sx={{
                    display: { xs: "none", md: "flex" },
                    backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 1) 11%, rgba(84, 84, 84, 0) 100%), url(${login_bgImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    boxShadow: 5,
                    width: "100%",
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <img src={logo_login_image} alt="logo" width="213px" height="61px" />
                <Box display="flex" justifyContent="center" flexWrap="wrap" mt={2}>
                    <Box sx={{ color: "#fff" }}>
                        <Typography variant="h1" textAlign="center" sx={{ mb: "1px" }}>
                            The Best Gold
                        </Typography>
                        <Typography variant="h2" textAlign="center" sx={{ mb: 2 }}>
                            Staking Company
                        </Typography>
                        <Typography
                            variant="body1"
                            textAlign="center"
                            sx={{
                                lineHeight: "1.6",
                                color: "#b0aaaa",
                                maxWidth: "500px",
                                letterSpacing: "0px",
                                opacity: 1,
                            }}
                        >
                            We are committed to providing our customers with exceptional
                            service while offering our employees the best training service.
                        </Typography>
                    </Box>
                </Box>
            </Grid>

            {/* Right Section */}
            <Grid
                item
                xs={12}
                md={7}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#FFFFFF",
                    width: "100%",
                    position: "relative",
                }}
            >
                <IconButton
                    sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        fontSize: "10px",
                    }}
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 4,
                    }}
                >
                    <Typography
                        variant="h3"
                        textAlign="center"
                        sx={{ fontWeight: "600", mb: 4 }}
                    >
                        Registration
                    </Typography>

                    {/* Stepper */}

                    <DashedStepper
                        activeStep={activeStep}
                        alternativeLabel
                        sx={{ width: "100%" }}
                    >
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel
                                    StepIconProps={{
                                        sx: {
                                            color: activeStep === index ? "blue" : "gray", // Circle color for the active and inactive steps
                                            "&.Mui-active": {
                                                color: "#C38F51", // Custom color for the active step
                                            },
                                            "&.Mui-completed": {
                                                color: "#C38F51", // Custom color for completed steps
                                            },
                                        },
                                    }}
                                >
                                    <Typography variant="body1">{label}</Typography>
                                </StepLabel>
                            </Step>
                        ))}
                    </DashedStepper>

                    {/* Formik Form */}
                    <Grid
                    // sx={{ width: "100%" }}
                    >
                        <Formik
                            // initialValues={{
                            //   first_name: "",
                            //   last_name: "",
                            //   type: "",
                            //   referred_by: "",
                            //   email: "",
                            //   phone: "",
                            //   isVerified: false,
                            //   password: "",
                            //   confirmPassword: "",
                            //   countrycode: "",

                            //   country: "",
                            //   state: "",
                            //   city: "",
                            //   zip_code: "",
                            //   address_1: "",
                            //   address_2: "",
                            //   profileImage: "",
                            // }}
                            // // validationSchema={validationSchema}
                            // validationSchema={
                            //   activeStep === 1 ? validationSchemaStep1 : validationSchema
                            // }

                            // onSubmit={(values) => {
                            //   if (activeStep === steps.length - 1) {
                            //     regiterFormSubmitionFn(values, uploadedFiles);
                            //   } else {
                            //     handleNext();
                            //   }
                            // }}

                            initialValues={getInitialValues()}
                            // enableReinitialize
                            // enableReinitialize={false}
                            // enableReinitialize={isFirstRender}
                            enableReinitialize={isFirstRender}
                            validationSchema={
                                activeStep === 1 ? validationSchemaStep1 : validationSchema
                            }
                            onSubmit={(values) => {
                                setFormValues((prevValues) => ({ ...prevValues, ...values })); // Merge new values

                                if (activeStep === steps.length - 1) {
                                    regiterFormSubmitionFn(
                                        { ...formValues, ...values },
                                        uploadedFiles
                                    );
                                } else {
                                    handleNext();
                                }
                            }}
                            onReset={() => setIsFirstRender(false)}
                        >
                            {({
                                handleChange,
                                handleBlur,
                                values,
                                setFieldValue,
                                isSubmitting,
                            }) => (
                                <Form>
                                    <Grid
                                        sx={{
                                            height: "57vh",
                                            overflowY: "auto",
                                            width: "100%",
                                            px: { xs: 0, md: 2 },
                                        }}
                                        className="content"
                                    >
                                        {activeStep === 0 && (
                                            <>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                // mt={3}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{ display: "flex", justifyContent: "center" }}
                                                    >
                                                        {/* <ProfileUpload name="imageupload" setFieldValue={setFieldValue} /> */}
                                                        <CustImageUploadInput
                                                            label="Add cover image"
                                                            name="profileImage"
                                                            isSubmitting={isSubmitting}
                                                            viewImgApi={viewImgApi}
                                                            setViewImgApi={setViewImgApi}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputLogin
                                                            // important="important"
                                                            important={true}
                                                            name="first_name"
                                                            label="First Name"
                                                            inputType="text"
                                                            custPlaceholder="Enter First Name "
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputLogin
                                                            name="last_name"
                                                            label="Last Name"
                                                            inputType="text"
                                                            custPlaceholder="Enter Last Name"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustoDropDownRegister
                                                            important={true}
                                                            name="role"
                                                            label="Type"
                                                            custPlaceholder="Select Type"
                                                            options={[
                                                                { label: "Individual", value: "customer" },
                                                                {
                                                                    label: "Institutional",
                                                                    value: "finance_consultant",
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputLogin
                                                            name="referred_by"
                                                            label="Referral Code (If any)"
                                                            custPlaceholder="Referral Code"
                                                            inputType="text"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomEmailVerifyInput
                                                            important={true}
                                                            setIsVerified={setIsEmailVerified}
                                                            isVerified={isEmailVerified}
                                                            emailInput={emailInput}
                                                            setEmailInput={setEmailInput}
                                                            name="email"
                                                            label="Email ID"
                                                            inputType="email"
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={6}>
                                                        <CustomPhoneVerifyInput
                                                            important={true}
                                                            name="phone"
                                                            label="Mobile Number"
                                                            countrycodename="countrycode"
                                                            setIsVerified={setIsVerified}
                                                            isVerified={isVerified}
                                                            phoneInput={phoneInput}
                                                            setPhoneInput={setPhoneInput}
                                                            custPlaceholder="Enter Mobile Number"
                                                            setFieldValue={setFieldValue} // Pass setFieldValue to CustomPhoneVerifyInput
                                                        />
                                                    </Grid>
                                                    {/* <Grid item xs={12} md={6}>
                                                        <CustomInputLogin
                                                            important={true}
                                                            name="password"
                                                            label="Password"
                                                            inputType="password"
                                                            custPlaceholder="Enter Password"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputLogin
                                                            important={true}
                                                            name="confirmPassword"
                                                            label="Confirm Password"
                                                            inputType="password"
                                                            custPlaceholder="Re-Enter Password"
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </>
                                        )}

                                        {activeStep === 1 && (
                                            <>
                                                {/* Address Section (Step 2) */}
                                                <Grid container spacing={2} mt={1}>
                                                    {/* Add address-related fields here */}
                                                    {/* <Grid item xs={12} md={6}>
                            <CustoDropDownRegister
                              name="country"
                              label="County"
                              custPlaceholder="Select Country"
                              // options={[
                              //   {
                              //     label: "United Arab Emirates",
                              //     value: "United Arab Emirates",
                              //   },
                              //   { label: "India", value: "India" },
                              // ]}
                              options={countryNames}
                              onChange={handleCountryChange} 

                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <CustoDropDownRegister
                              name="state"
                              label="State"
                              custPlaceholder="Select State"
                              options={[
                                { label: "Abhu Dhabi", value: "Abhu Dhabi" },
                                { label: "Tamil Nadu", value: "Tamil Nadu" },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <CustoDropDownRegister
                              name="city"
                              label="City"
                              custPlaceholder="Select City"
                              options={[
                                { label: "AI Ain", value: "AI Ain" },
                                { label: "Chennai", value: "Chennai" },
                              ]}
                            />
                          </Grid> */}

                                                    <Grid item xs={12} md={6}>
                                                        <CustoDropDownRegister
                                                            name="country"
                                                            label="Country"
                                                            custPlaceholder="Select Country"
                                                            options={countryNames}
                                                            onChange={handleCountryChange}

                                                        />
                                                    </Grid>

                                                    {/* State Dropdown */}
                                                    <Grid item xs={12} md={6}>
                                                        <CustoDropDownRegister
                                                            name="state"
                                                            label="State"

                                                            custPlaceholder="Select State"
                                                            options={stateNames}
                                                            onChange={handleStateChange}

                                                        />
                                                    </Grid>

                                                    {/* City Dropdown */}
                                                    <Grid item xs={12} md={6}>
                                                        <CustoDropDownRegister
                                                            name="city"
                                                            label="City"
                                                            custPlaceholder="Select City"
                                                            options={cityNames}
                                                        // value={reApprovalData?.city || ""}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputLogin
                                                            name="zip_code"
                                                            label="Zipcode"
                                                            custPlaceholder="Enter Zipcode"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputLogin
                                                            name="address_1"
                                                            label="Address1"
                                                            custPlaceholder="Enter address1"
                                                            inputType="textarea"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomInputLogin
                                                            name="address_2"
                                                            label="Address2"
                                                            custPlaceholder="Enter address2"
                                                            inputType="textarea"
                                                        />
                                                    </Grid>
                                                    {/* Add other address fields as needed */}
                                                </Grid>
                                            </>
                                        )}
                                        {activeStep === 2 && (
                                            <>
                                                {/* Documents Section (Step 3) */}
                                                <Grid
                                                    container
                                                    spacing={4}
                                                    // mt="1px"
                                                    pt={2}
                                                >
                                                    {/* Left Side: Dropdown and Upload Button */}
                                                    <Grid item xs={12} md={6}>
                                                        {/* Dropdown for Document Type */}
                                                        {/* <FormControl fullWidth>
                                                            <InputLabel>Document Type</InputLabel>
                                                            <Select
                                                                name="documentType"
                                                                value={selectedDocumentType}
                                                                onChange={(e) => setSelectedDocumentType(e.target.value)}
                                                            >
                                                                <MenuItem value="Emirates ID">Emirates ID</MenuItem>
                                                                <MenuItem value="Passport">Passport</MenuItem>
                                                                <MenuItem value="Driving License">Driving License</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                        <CustomDropdownDocumentType
                                                            name="emiratesid"
                                                            label="Document Type"
                                                            custPlaceholder="Select Document Type"
                                                            setFieldValue={setFieldValue}
                                                            selectedDocumentType={selectedDocumentType}
                                                            setSelectedDocumentType={setSelectedDocumentType}
                                                            // options={[
                                                            //     { label: "Abhu Dhabi", value: "Abhu Dhabi" },
                                                            //     { label: "Tamil Nadu", value: "Tamil Nadu" },
                                                            // ]}
                                                            // options={options}
                                                            options={DocumentNames}

                                                        />

                                                        {/* File Upload Button */}
                                                        <Box
                                                            mt={2}
                                                            sx={{
                                                                border: "1px solid #E5E5E5",
                                                                p: 4,
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    mb: "10px",
                                                                }}
                                                            >
                                                                <FiUpload
                                                                    style={{ color: "#C38F51", fontSize: "40px" }}
                                                                />
                                                            </Box>

                                                            <input
                                                                accept=".png,.jpg,.pdf"
                                                                style={{ display: "none" }}
                                                                id="file-upload"
                                                                type="file"
                                                                onChange={handleFileUpload}
                                                            />
                                                            <label htmlFor="file-upload">
                                                                <Button
                                                                    component="span"
                                                                    sx={{
                                                                        color: "white",
                                                                        background: "#C38F51",
                                                                        borderRadius: "23px",
                                                                        p: "0 10px",
                                                                        fontSize: "14px",
                                                                        textTransform: "none",
                                                                    }}
                                                                >
                                                                    Browse
                                                                </Button>
                                                            </label>

                                                            <Typography
                                                                variant="body2"
                                                                color="#E5E5E5"
                                                                mt={1}
                                                            >
                                                                drop a file here
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="#252525"
                                                                mt={1}
                                                                fontSize="12px"
                                                            >
                                                                <span style={{ color: "red" }}>*</span> File
                                                                supported: .png, .jpg, .pdf
                                                            </Typography>
                                                        </Box>
                                                    </Grid>

                                                    {/* Right Side: Uploaded Files */}
                                                    <Grid item xs={12} md={6}>
                                                        {/* <Grid container spacing={2}> */}
                                                        <Typography variant="subtitle1" mb={0.5}>
                                                            Document Type
                                                        </Typography>
                                                        {/* <Grid>
                              {uploadedFiles.length > 0 ? (
                                uploadedFiles.map((file, index) => (
                                  <Grid item xs={12} key={index}>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        paddingBottom: "5px",
                                        paddingRight: "5px",
                                        paddingLeft: "5px",

                                        background: "#F7F7F7",
                                        border: "none",
                                        boxShadow: "none",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <HiDocumentArrowUp
                                          style={{
                                            fontSize: "30px",
                                            color: "#C38F51",
                                            marginRight: "10px",
                                          }}
                                        />
                                      </Box>
                                      <Box
                                        sx={{ width: "-webkit-fill-available" }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            sx={{ fontWeight: "800px" }}
                                          >
                                            {file.type}
                                          </Typography>
                                          <IconButton
                                            onClick={() =>
                                              handleFileDelete(index)
                                            }
                                            sx={{
                                              color: "black",
                                              background: "none",
                                              "&:hover": {
                                                color: "red",
                                                background: "none",
                                              },
                                            }}
                                          >
                                            <RiDeleteBin2Line
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "800",
                                              }}
                                            />
                                          </IconButton>
                                        </Box>
                                        <LinearProgress
                                          variant="determinate"
                                          value={file.progress}
                                          sx={{
                                            height: "6px",
                                            borderRadius: "10px",
                                            backgroundColor: "lightgray",
                                            "& .MuiLinearProgress-bar": {
                                              backgroundColor:
                                                file.progress === 100
                                                  ? "#1FC45B"
                                                  : "red",
                                            },
                                          }}
                                        />
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                        >
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              fontSize: "12px",
                                              color: "gray",
                                            }}
                                          >
                                            {file.name}
                                          </Typography>
                                          <Typography
                                            variant="body1"
                                            fontSize="12px"
                                            color={
                                              file.progress === 100
                                                ? "green"
                                                : "red"
                                            }
                                          >
                                            {file.progress === 100
                                              ? "Upload Successful!"
                                              : `${file.progress}%`}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Paper>
                                  </Grid>
                                ))
                              ) : (
                                <>
                                  <Box
                                    sx={{
                                      textAlign: "center",
                                      color: "#E5E5E5",
                                    }}
                                  >
                                    <AiOutlineProfile
                                      style={{ fontSize: "100px" }}
                                    />
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        color: "#E5E5E5",
                                        textAlign: "center",
                                      }}
                                    >
                                      No uploads available.
                                    </Typography>
                                  </Box>
                                </>
                              )}
                            </Grid> */}

                                                        <Grid>
                                                            {uploadedFiles.length > 0 ? (
                                                                uploadedFiles.map((file, index) => (
                                                                    <Grid item xs={12} key={index}>
                                                                        <Paper
                                                                            elevation={3}
                                                                            style={{
                                                                                padding: "5px",
                                                                                background: "#F7F7F7",
                                                                                boxShadow: "none",
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <HiDocumentArrowUp
                                                                                    style={{
                                                                                        fontSize: "30px",
                                                                                        color: "#C38F51",
                                                                                        marginRight: "10px",
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                            <Box
                                                                                sx={{ width: "-webkit-fill-available" }}
                                                                            >
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "space-between",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        sx={{ fontWeight: "800px" }}
                                                                                    >
                                                                                        {file.type}
                                                                                    </Typography>
                                                                                    <Box>
                                                                                        <IconButton
                                                                                            onClick={() =>
                                                                                                handlePreview(file.fileUrl)
                                                                                            }
                                                                                            sx={{
                                                                                                color: "black",
                                                                                                "&:hover": { color: "blue" },
                                                                                            }}
                                                                                        >
                                                                                            <AiOutlineEye
                                                                                                style={{ fontSize: "16px" }}
                                                                                            />
                                                                                        </IconButton>
                                                                                        <IconButton
                                                                                            onClick={() =>
                                                                                                handleFileDelete(index)
                                                                                            }
                                                                                            sx={{
                                                                                                color: "black",
                                                                                                "&:hover": { color: "red" },
                                                                                            }}
                                                                                        >
                                                                                            <RiDeleteBin2Line
                                                                                                style={{ fontSize: "14px" }}
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                </Box>
                                                                                <LinearProgress
                                                                                    variant="determinate"
                                                                                    value={file.progress}
                                                                                    sx={{
                                                                                        height: "6px",
                                                                                        borderRadius: "10px",
                                                                                        backgroundColor: "lightgray",
                                                                                        "& .MuiLinearProgress-bar": {
                                                                                            backgroundColor:
                                                                                                file.progress === 100
                                                                                                    ? "#1FC45B"
                                                                                                    : "red",
                                                                                        },
                                                                                    }}
                                                                                />
                                                                                <Box
                                                                                    display="flex"
                                                                                    justifyContent="space-between"
                                                                                >
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        sx={{
                                                                                            fontSize: "12px",
                                                                                            color: "gray",
                                                                                        }}
                                                                                    >
                                                                                        {file.name}
                                                                                    </Typography>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        fontSize="12px"
                                                                                        color={
                                                                                            file.progress === 100
                                                                                                ? "green"
                                                                                                : "red"
                                                                                        }
                                                                                    >
                                                                                        {file.progress === 100
                                                                                            ? "Upload Successful!"
                                                                                            : `${file.progress}%`}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Paper>
                                                                    </Grid>
                                                                ))
                                                            ) : (
                                                                <>
                                                                    <Box
                                                                        sx={{
                                                                            textAlign: "center",
                                                                            color: "#E5E5E5",
                                                                        }}
                                                                    >
                                                                        <AiOutlineProfile
                                                                            style={{ fontSize: "100px" }}
                                                                        />
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                color: "#E5E5E5",
                                                                                textAlign: "center",
                                                                            }}
                                                                        >
                                                                            No uploads available.
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                            )}

                                                            {/* Preview Modal */}
                                                            <Dialog
                                                                open={Boolean(previewFile)}
                                                                // onClose={handleClosePreview}
                                                                fullWidth
                                                                maxWidth="md"
                                                                className="custom-dialog"
                                                                sx={{
                                                                    "& .MuiPaper-root": {
                                                                        maxWidth: "800px",
                                                                        // borderRadius: "14px",
                                                                    },
                                                                }}
                                                            >
                                                                <IconButton
                                                                    aria-label="Close"
                                                                    onClick={handleClosePreview}
                                                                    sx={{
                                                                        position: "absolute",
                                                                        top: 8,
                                                                        right: 8,
                                                                        color: "white",
                                                                    }}
                                                                >
                                                                    <MdCancel />
                                                                </IconButton>
                                                                <Box>
                                                                    {previewFile &&
                                                                        (previewFile.includes("uploads/images/") ? (
                                                                            <img
                                                                                src={`${adminUrl}${previewFile}`}
                                                                                alt="Preview"
                                                                                style={{ width: "100%" }}
                                                                            />
                                                                        ) : previewFile.includes(
                                                                            "uploads/pdfs/"
                                                                        ) ? (
                                                                            <iframe
                                                                                src={`${adminUrl}${previewFile}`}
                                                                                title="PDF Preview"
                                                                                width="100%"
                                                                                height="500px"
                                                                            />
                                                                        ) : null)}

                                                                    {/* <img
                                    src={`${adminUrl}${previewFile}`}
                                    // alt={previewFile.name}
                                    style={{ width: "100%" }}
                                  />
                                  ) : previewFile?.type.includes("pdf") ? (
                                  <iframe
                                    // src={previewFile.url}

                                    src={`${adminUrl}${previewFile}`}
                                    width="100%"
                                    height="500px"
                                    title={previewFile.name}
                                  />
                                  ) : (
                                  <Typography>
                                    Preview not available.
                                  </Typography>
                                  )} */}
                                                                </Box>
                                                            </Dialog>
                                                        </Grid>
                                                        {/* </Grid> */}
                                                    </Grid>
                                                    <Grid pl={1}>
                                                        <TermsandConditions
                                                            checked={checked}
                                                            onCheckChange={handleCheckChange}
                                                        />
                                                    </Grid>
                                                    {/* <Typography>I accept all the Terms & Conditions and Privacy Policies of goldstake.com</Typography> */}
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                    <Box mt={1} textAlign="right">
                                        {activeStep > 0 ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleBack}
                                                //  sx={{ mr: 2, color: "black", background: "white",borderRadius:"23px",textTransform:"none", }}
                                                sx={{
                                                    // backgroundColor: "#C38F51",
                                                    textTransform: "none",
                                                    color: "white",
                                                    fontSize: "16px",
                                                    mr: "10px",
                                                    // padding: "0px 40px",
                                                    padding: { xs: "0px 20px", md: "0px 40px" },

                                                    minHeight: "40px",
                                                    borderRadius: "23px",
                                                    background: "white",
                                                    boxShadow: "none",
                                                    color: "black",
                                                    border: "1px solid black",
                                                    "&:hover": {
                                                        backgroundColor: "#3B4056",
                                                        color: "white",
                                                    },
                                                }}
                                            >
                                                Back
                                            </Button>
                                        ) : null}
                                        {activeStep === steps.length - 1 ? (
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                // onClick={handleGoRegisterComplete}

                                                // onClick={handleGoRegisterComplete}
                                                disabled={!checked}
                                                // sx={{ background: "#C38F51", color: "white", borderRadius: "23px", textTransform: "none" }}
                                                sx={{
                                                    // backgroundColor: "#C38F51",
                                                    textTransform: "none",
                                                    color: "white",
                                                    fontSize: "16px",
                                                    boxShadow: "none",

                                                    // padding: "0px 40px",
                                                    padding: { xs: "0px 20px", md: "0px 40px" },

                                                    minHeight: "40px",
                                                    borderRadius: "23px",
                                                    background: "#C38F51 0% 0% no-repeat padding-box",
                                                    "&:hover": {
                                                        backgroundColor: "#3B4056",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                // onClick={handleGoRegisterComplete}
                                                // sx={{ textTransform: "none", background: "#C38F51", color: "white", borderRadius: "23px" }}
                                                sx={{
                                                    // backgroundColor: "#C38F51",
                                                    textTransform: "none",
                                                    color: "white",
                                                    fontSize: "16px",
                                                    // padding: "0px 40px",
                                                    padding: { xs: "0px 20px", md: "0px 40px" },

                                                    minHeight: "40px",
                                                    boxShadow: "none",

                                                    borderRadius: "23px",
                                                    background: "#C38F51 0% 0% no-repeat padding-box",
                                                    "&:hover": {
                                                        backgroundColor: "#3B4056",
                                                    },
                                                }}
                                            >
                                                Next
                                            </Button>
                                        )}
                                        {/* <Button variant="contained" type="submit"
                                            onClick={handleGoRegisterComplete}
                                            sx={{ background: "#C38F51", color: "white" }}>
                                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                        </Button> */}
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Box>
                <ToastContainer
                    position="top-right" // or "top-center", "bottom-left", etc.
                    autoClose={1500} // Auto close duration in ms
                    hideProgressBar={false} // Hide or show progress bar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />

                <WarningModal
                    open={isWarningModalOpen}
                    onClose={handleWarningCloseModal}
                />
            </Grid>
        </Grid>
    );
};

export default ReApprovalRegisterComponent;
