
import { Typography, Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const RejectedStatus = () => {

    const navigate=useNavigate()
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            bgcolor="#F8F8F8"
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                maxWidth="450px"
                padding={4}
                borderRadius="12px"
                boxShadow="0px 4px 10px rgba(0,0,0,0.1)"
                bgcolor="white"
            >
                <Typography variant="h4" fontWeight="bold" color="rgb(132 127 122 / 87%)">
                    Reminder
                </Typography>
                <Typography variant="body1" color="text.secondary" marginY={2}>
                    The customer is not in rejected status, so reapproval is not required.
                    Your registration has been submitted; please wait for approval.
                </Typography>
                <Button
                onClick={()=>{navigate("/")}}
                    sx={{
                        textTransform: "none",
                        color: "white",
                        fontSize: "16px",
                        padding: "10px 30px",
                        borderRadius: "23px",
                        backgroundColor: "#C38F51",
                        "&:hover": {
                            backgroundColor: "#A37440",
                        },
                    }}
                >
                    Go Back
                </Button>
            </Box>
        </Box>
    );
};

export default RejectedStatus;
