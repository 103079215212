
import React, { useState, useRef, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, IconButton, Tooltip } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import axios from "axios";
import { RiUser6Fill } from "react-icons/ri";
import { adminUrl as baseUrl } from "../../constants"; // Assuming the baseUrl is needed

const CustImageUploadInput = ({ label, name, isSubmitting,isFinalSubmit,isFormSubmitted,setViewImgApi,viewImgApi, ...rest }) => {
    // const [viewImgApi, setViewImgApi] = useState(null);
    // const fileInputRef = useRef(null);

    const handleImageChange = async (event, setFieldValue) => {
        const file = event.currentTarget.files[0];
        try {
            const formData = new FormData();
            formData.append("uploadImage", file);

            const response = await axios.post(
                `${baseUrl}uploadImage`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            const data = response.data;
            if (data && data.data && data.data.imageUrl) {
                const imageUrl = data.data.imageUrl;
                setFieldValue(name, `${baseUrl}${imageUrl}`);
                setViewImgApi(`${baseUrl}${imageUrl}`);
            } else {
                console.error("Image URL not found in response:", data);
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    // useEffect(() => { 
    //     if (isSubmitting) {
    //         setViewImgApi(null);
    //         if (fileInputRef.current) {
    //             fileInputRef.current.value = ""; // Clear file input value
    //         }
    //     }
    // }, [isSubmitting]);


    return (
        <Box className="image-box">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    mt: 4,
                }}
            >
                <Box sx={{ position: "relative", display: "inline-block" }}>
                    <Avatar
                        sx={{
                            width: 70,
                            height: 70,
                            background: "black",
                        }}
                        src={viewImgApi}
                    >
                        {!viewImgApi && (
                            <Box
                                component="span"
                                sx={{
                                    fontSize: 40,
                                    color: "white",
                                    background: "black",
                                }}
                            >
                                <RiUser6Fill />
                            </Box>
                        )}
                    </Avatar>
                    <Tooltip title="Upload">
                        <IconButton
                            sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                bgcolor: "#C38F51",
                                "&:hover": { bgcolor: "#C38F51" }
                            }}
                            color="primary"
                            component="label"
                        >
                            <Field name={name}>
                                {({ form }) => (
                                    <input
                                        // ref={fileInputRef}
                                        id={name}
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            handleImageChange(event, form.setFieldValue)
                                        }
                                        // className="image-box-input"

                                        style={{
                                            opacity: 0,
                                            cursor: "pointer",
                                            width: "0px",
                                            height:"0px"

                                        }}
                                        {...rest}
                                    />
                                )}
                            </Field>
                            <PhotoCamera sx={{ color: "white", fontSize: "15px" }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <ErrorMessage name={name} component="div" className="inputs-error-msg" />
        </Box>
    );
};

export default CustImageUploadInput;
