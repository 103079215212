import React, { useState } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Divider,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Checkbox,
  Stack,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { MdCancel } from "react-icons/md";
import { CiSquarePlus, CiSquareMinus } from "react-icons/ci";
import { Formik, Form, Field } from "formik";
import { AiFillThunderbolt } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import "../../Pages/StakeNow/AddToCart/CartPageComponent/Modal/BannerinputGetModal.css";
import { IoIosWarning } from "react-icons/io";

const WarningModal = ({
  open,
  onClose,
  setSubmittedValues,
  submittedValues,
  modalHeadingName,
  handleNextModal,
}) => {
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      //   onClose={onClose}
      fullWidth
      maxWidth="md"
      className="custom-dialog"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "500px",
          //   borderRadius: "2px",
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        {/* <Box className="dialog-heading">
          <IconButton
            aria-label="Close"
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "text.secondary",
            }}
          >
            <MdCancel />
          </IconButton>
          <DialogTitle className="custom-dialog-title">
            <Box>
              <Typography className="custom-title-text" color="white">
                Purchase Document
              </Typography>
            </Box>
          </DialogTitle>
        </Box> */}

        <DialogContent
          dividers
          sx={{
            padding: 0,
            "& .MuiDialogContent-root": {
              padding: 0,
            },
            color: "#7A7A7A",
            p: { xs: 2, md: "16px 24px" },
          }}
        // sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              //   justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <IoIosWarning
                style={{
                  // color: "#eba30a",
                  color: "#C38F51",

                  fontSize: "25px",
                  marginRight: "5px",
                }}
              />
            </Box>
            <Typography
              variant="subtitle2"
              color="black"
              textAlign="center"
              sx={{ fontSize: "18px", fontWeight: "500" }}
            >
              Upload Documents
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ mt: 2, ml: 1 }}>
              <Typography>
                {/* Please upload required documents before register */}
                Please upload the required documents to complete your
                registration process. Ensuring all necessary files are submitted
                will help avoid any delays in verification.
              </Typography>
            </Box>
          </Grid>

          <Grid xs={12}>
            <Grid mt={1} sx={{ display: "flex", justifyContent: "end" }}>
              <Stack spacing={2} direction="row">

                <Button
                  onClick={() => onClose()}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    color: "white",
                    fontSize: "14px",
                    boxShadow: "none",
                    marginTop: "20px",
                    padding: { xs: "0px 10px", md: "0px 20px" },
                    minHeight: "40px",
                    // borderRadius: "23px",
                    // background: "#eba30a 0% 0% no-repeat padding-box",
                    background: "#C38F51 0% 0% no-repeat padding-box",

                    // "&:hover": {
                    //   backgroundColor: "#3B4056",
                    //   boxShadow: "none",
                    // },
                  }}
                //   disabled={!values.privacyChecked} // Disable button if checkbox is not checked
                >
                  OK
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default WarningModal;
