


import React, { useEffect } from "react";
import { Box, Typography, Avatar, Button, Paper, Grid, Divider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import bgImage from "../../Assets/StakeNowAddToCart.png"
import profileImage from "../../Assets/User_icon_image.svg"

import { useDispatch, useSelector } from "react-redux";
import actions from "../../ReduxStore/actions";
import { adminUrl } from "../../constants";

const ProfileComponent = () => {


  const { getCustomerById } = useSelector((state) => state?.getCustomerById);

  // console.log(getCustomerById, "getCustomerById")
  // const sessioncustomer_id = sessionStorage.getItem("sessioncustomer_id");

  // const dispatch = useDispatch()
  // useEffect(() => {
  //   if (sessioncustomer_id) {
  //     const fetchData = async () => {
  //       const data1 = {
  //         data: {},
  //         method: "get",
  //         apiName: `getCustomerById/${sessioncustomer_id}`,
  //       };
  //       await dispatch(actions.GETCUSTOMERBYID(data1));
  //     };
  //     fetchData();
  //   }
  // }, []);


  return (
    <Box>
      {/* Background Header */}
      <Box
        sx={{
          background: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "180px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h4" color="white" fontWeight="bold">
          Profile
        </Typography>

      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Avatar
          src={`${getCustomerById?.data?.profileImage}` ||  `${profileImage}`}
          alt="John Doe"
          sx={{
            width: 150,
            height: 150,
            border: "4px solid white",
            marginTop: "-80px",
            marginLeft: "50px"
          }}
        />
        <Typography variant="h5" fontWeight="bold">
          {getCustomerById?.data?.first_name} {getCustomerById?.data?.last_name} 
        </Typography>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }}></Divider>
      {/* Profile Content */}
      <Paper
        sx={{
          position: "relative",
          // marginTop: "-50px",
          padding: "24px",
          // borderRadius: "16px",
          width: "100%",
          maxWidth: "1100px",
          mx: "auto",
          border: "none",
          boxShadow: "none",
          p:2

          // boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
        }}
      // elevation={3}
      >

        {/* Profile Header */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {/* Avatar + Name */}


          {/* Edit Button */}
          {/* <Button
            variant="outlined"
            startIcon={<EditIcon />}
            sx={{
              borderColor: "#b38233",
              color: "#b38233",
              textTransform: "capitalize",
              borderRadius: "20px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#f5f2e6",
                borderColor: "#b38233",
              },
            }}
          >
            Edit
          </Button>
          */}
        </Box>

        {/* Personal Details Section */}
        <Typography variant="h6" fontWeight="bold">
          Personal Details
        </Typography>
        <Paper
          sx={{
            padding: "16px",
            marginTop: "10px",
            borderRadius: "8px",
            backgroundColor: "#f7f7f7",
            boxShadow: "none"

          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography fontWeight="bold">First Name</Typography>
              <Typography>{getCustomerById?.data?.first_name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Last Name</Typography>
              <Typography>{getCustomerById?.data?.last_name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Mobile Number</Typography>
              <Typography>{getCustomerById?.data?.phone}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Email</Typography>
              <Typography>{getCustomerById?.data?.email}</Typography>
            </Grid>
          </Grid>
        </Paper>

        {/* Address Section */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Typography variant="h6" fontWeight="bold">
            Address
          </Typography>
          {/* <Button
            variant="outlined"
            startIcon={<EditIcon />}
            sx={{
              borderColor: "#b38233",
              color: "#b38233",
              textTransform: "capitalize",
              borderRadius: "20px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#f5f2e6",
                borderColor: "#b38233",
              },
            }}
          >
            Edit
          </Button> */}
        </Box>
        <Paper
          sx={{
            padding: "16px",
            marginTop: "10px",
            borderRadius: "8px",
            backgroundColor: "#f7f7f7",
            boxShadow: "none"
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Address Line 1</Typography>
              <Typography>{getCustomerById?.data?.address_1}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Address Line 2</Typography>
              <Typography>{getCustomerById?.data?.address_2}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">City</Typography>
              <Typography>{getCustomerById?.data?.country}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">City</Typography>
              <Typography>{getCustomerById?.data?.state}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">State</Typography>
              <Typography>{getCustomerById?.data?.city}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    </Box>
  );
};

export default ProfileComponent;
