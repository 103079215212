

import React from "react";
import "../CustomInputLogin/CustomInputLogin.css";
import "../ComponentsCss/componet.css";
import { Field, ErrorMessage } from "formik";
import { InputAdornment, Typography } from "@mui/material";
import { Email } from "@mui/icons-material";
import { FaStarOfLife } from "react-icons/fa6";

const CustomInputLogin = ({ label, name, custPlaceholder, image, important,handleChange, inputType, ...rest }) => {
  // const validateInput = (value) => {
  //   let error;
  //   if (!value) {
  //     error = `Field is Required`;
  //   } else if (inputType === "number" && isNaN(value)) {
  //     error = "Field is Required";
  //   }
  //   return error;
  // };

  

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <div
          style={{ marginBottom: "5px" }}
        >
          <Typography variant="body1">
            <label
              htmlFor={name}
              className="input-heading12"
              style={{ fontSize: "15px", fontWeight: "450" }}
            >
              {label}
            </label>

            {important && (
              <FaStarOfLife style={{ color: '#F53E5C', fontSize: "8px", marginLeft: "3px" }} />
            )}
          </Typography>
        </div>
        <div style={{ position: "relative", width: "100%" }}>
          {/* Email Icon as Start Adornment */}
          {image && (
            < InputAdornment position="start" style={{ position: "absolute", top: "50%", left: "8px", transform: "translateY(-50%)" }}>
              <img src={image} style={{ color: "black", height: "17px" }} />
            </InputAdornment>
          )}
          {/* Input Field */}
          <Field
            id={name}
            // inputType={inputType}
            // onChange={handleChange}
            type={inputType !== "textarea" ? inputType : "text"}
            name={name}
            as={inputType === "textarea" ? "textarea" : "input"}
            placeholder={custPlaceholder || "Enter Input"}
            {...rest}
            style={{
              width: "100%",
              borderRadius: "3px",
              // paddingLeft: "40px", // Add padding to accommodate the icon
              boxSizing: "border-box",
              height: inputType === "textarea" ? "100px" : "35px",
              paddingLeft: inputType === "textarea" ? "10px" : "10px" // Add padding to accommodate the icon

            }}
            // sx={inputType === "textarea" ? { height: "100px" || "100px" } : {}}
            className="custominput-field12"
            // validate={validateInput}
          />
        </div>

        <ErrorMessage
          name={name}
          component="div"
          className="inputs-error-msg"
          style={{ color: "red", fontSize: "12px", fontWeight: '400' }}
        />
      </div>
    </div >
  );
};

export default CustomInputLogin;
