

import React from "react";
import { Box, Button, Typography, Container, Stack } from "@mui/material";
import contactUsBgImage from "../../../Assets/contactUsBgImage.png";
// import home_side_image from "../../Assets/Overlay.png"; // Ensure this is the correct path
import { useNavigate } from "react-router-dom";

import actions from "../../../ReduxStore/actions/index";
import { useDispatch } from "react-redux";
import { adminUrl } from "../../../constants";

const ContactUsMainPage = ({ bannerDates }) => {
    const navigate = useNavigate()

    const handleGoLoginpage = () => {
        navigate("/login")
    }

    const handleGoStakeNowPage = () => {
        navigate("/stake-now")
    }
    return (
        <Box
            sx={{
                // position: "relative", // Needed for positioning child elements
                background: `linear-gradient(90deg, #000000 0%, #54545400 100%), url(${adminUrl}${bannerDates?.[0]?.additional_data?.[0]?.banner})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
                padding: "80px 0px",
                // left:"100px"
                // minHeight: "90vh", // Adjust height as needed
            }}
        >
            <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                <Box
                    display="flex"
                    // alignItems="center"
                    justifyContent="start"
                    flexWrap="wrap"
                >
                    {/* Left Section */}
                    <Box
                        sx={{

                            color: "#fff",
                        }}
                    >
                        <Typography
                            variant="h1"
                            textAlign="start"
                        // sx={{
                        //     fontWeight: "bold",
                        //     fontSize: "50px",
                        //     // mb:"1px"
                        // }}
                        >
                            {bannerDates?.[0]?.heading || "Default Heading"}
                            {/* Get in Touch */}

                        </Typography>
                        <Typography
                            variant="h2"
                            textAlign="start"
                            sx={{

                                mb: 2,
                            }}
                        >
                            {bannerDates?.[0]?.sub_heading || "Default Sub-heading"}
                            {/* Connecting Near & Far */}
                        </Typography>
                        <Typography
                            variant="body1"
                            textAlign="start"
                            sx={{
                                mb: 5,
                                lineHeight: "1.6",
                                color: "#ffffff",

                                maxWidth: "500px",


                                letterSpacing: "0px",

                                opacity: 1
                            }}
                        >
                            {bannerDates?.[0]?.content || "Default Content"}
                            {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley */}
                        </Typography>

                    </Box>
                </Box>
            </Container >
        </Box >
    );
};

export default ContactUsMainPage;
