

import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";

import sheme_small_image from "../../../Assets/sheme_small_image.png"
import "./AddToCartSchemesById.css"
import { useSelector } from "react-redux";


const AddToCartSchemesById = () => {

    const { getPlanById } = useSelector((state) => state?.getPlanById);
    console.log(getPlanById, "getPlanByIdgetPlanByIdgetPlanById")
    // const currentDate = new Date().toLocaleDateString();
    const currentDate = new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
    });
    return (
        <>
            <Box sx={{ py: 4 }}>
                <Box sx={{ textAlign: "center", mb: 4 }}>
                    <Typography
                        variant="h3"
                        gutterBottom
                        sx={{ paddingBottom: "5px" }}
                    >
                        <span style={{ color: "black", fontWeight: "800" }}>{getPlanById?.data?.title}</span>
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            paddingBottom: "5px",
                            maxWidth: "800px",
                            margin: "0 auto",
                            textAlign: "center",
                            color: "#b0aaaa",

                        }}
                    >
                        {getPlanById?.data?.description}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        position: "relative", // Needed for positioning child elements
                        background: `linear-gradient(90deg, #000000 0%, #2E2E2ED6 51%, #1D1D1D 100%) , url(${sheme_small_image})`,
                        // background: `linear-gradient(90deg, #000000 0%, #54545400 100%), url(${StakeNowAddToCart})`,
                        // background: `url(${StakeNowAddToCart}),linear- gradient(91deg, #000000 0 %, #2E2E2ED6 51 %, #1D1D1D 100 %)`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        overflow: "hidden",
                        padding: "40px 20px",
                        // left:"100px"
                        // minHeight: "90vh", // Adjust height as needed

                    }}
                >
                    <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                        <Box
                            display="flex"
                            // alignItems="center"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            color="white"
                        >
                            <Box>
                                <Typography variant="subtitle1" sx={{ mb: 0.4 }}>Value of Purchase</Typography>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: "#C38F51", fontSize: "19px" }}>
                                    $ {getPlanById?.data?.value_of_purchase}

                                </Typography>

                            </Box>
                            <Box>
                                <Typography variant="subtitle1" sx={{ mb: 0.4 }}>Date of Initiation</Typography>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: "#C38F51", fontSize: "19px" }}>
                                    {currentDate}

                                </Typography>

                            </Box>
                            <Box>
                                <Typography variant="subtitle1" sx={{ mb: 0.4 }}>Gold Staked</Typography>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: "#C38F51", fontSize: "19px" }}>
                                    {getPlanById?.data?.purchase_stake} Grm
                                </Typography>

                            </Box>
                            <Box>
                                <Typography variant="subtitle1" sx={{ mb: 0.4 }}>Reap Benifit</Typography>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: "#C38F51", fontSize: "19px" }}>
                                    {getPlanById?.data?.reap_percentage} *
                                    <span style={{ fontSize: "12px", paddingLeft: "4px", fontWeight: "none" }}>Per Quarter</span></Typography>

                            </Box>
                        </Box>
                    </Container>


                </Box>



            </Box>
        </>
    );
};

export default AddToCartSchemesById;
